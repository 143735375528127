import {
  anyUnit,
  isElectricityUnit,
  isGasUnit,
  isWaterUnit,
  isAirQualityUnit,
  isTemperatureUnit,
  isAreaUnit,
  isBooleanUnit,
  electricityUnit,
  gasUnit,
  waterUnit,
  temperatureUnit,
  airQualityUnit,
  areaUnit,
  booleanUnit,
  unknownUnit,
} from './unitEnums'

export enum unitCategory {
  gas = 'gas',
  water = 'water',
  electricity = 'electricity',
  temperature = 'temperature',
  airQuality = 'airQuality',
  area = 'area',
  boolean = 'boolean',
  unknown = 'unknown',
}

export const getCategory = (unit: anyUnit): unitCategory => {
  if (isElectricityUnit(unit)) {
    return unitCategory.electricity
  }
  if (isGasUnit(unit)) {
    return unitCategory.gas
  }
  if (isWaterUnit(unit)) {
    return unitCategory.water
  }
  if (isAirQualityUnit(unit)) {
    return unitCategory.airQuality
  }
  if (isTemperatureUnit(unit)) {
    return unitCategory.temperature
  }
  if (isAreaUnit(unit)) {
    return unitCategory.area
  }
  if (isBooleanUnit(unit)) {
    return unitCategory.boolean
  }
  return unitCategory.unknown
}

export const getCategoryDefaultUnit = (category: unitCategory) => {
  switch (category) {
    case unitCategory.electricity:
      return electricityUnit.kwh

    case unitCategory.gas:
      return gasUnit.metersCubed

    case unitCategory.water:
      return waterUnit.metersCubed

    case unitCategory.temperature:
      return temperatureUnit.celsius

    case unitCategory.airQuality:
      return airQualityUnit.ppm

    case unitCategory.area:
      return areaUnit.squareMeters

    case unitCategory.boolean:
      return booleanUnit.boolean

    case unitCategory.unknown:
      return unknownUnit.unknown
  }
}

export const getDefaultUnit = (unit: anyUnit) => {
  return getCategoryDefaultUnit(getCategory(unit))
}

export const getCategoryUnits = (category: unitCategory): anyUnit[] => {
  switch (category) {
    case unitCategory.electricity:
      return Object.values(electricityUnit)

    case unitCategory.gas:
      return Object.values(gasUnit)

    case unitCategory.water:
      return Object.values(waterUnit)

    case unitCategory.temperature:
      return Object.values(temperatureUnit)

    case unitCategory.airQuality:
      return Object.values(airQualityUnit)

    case unitCategory.area:
      return Object.values(areaUnit)

    case unitCategory.boolean:
      return Object.values(booleanUnit)

    case unitCategory.unknown:
      return [
        unknownUnit.unknown,
        electricityUnit.kwh,
        electricityUnit.btu,
        electricityUnit.kj,
        electricityUnit.carbonTonnesMetric,
        electricityUnit.carbonTonnesImperial,
        gasUnit.metersCubed,
        gasUnit.feetCubed,
        gasUnit.kwh,
        gasUnit.btu,
        gasUnit.kj,
        gasUnit.carbonTonnesMetric,
        gasUnit.carbonTonnesImperial,
        waterUnit.metersCubed,
        waterUnit.feetCubed,
        waterUnit.litres,
        waterUnit.gallonsImperial,
        waterUnit.gallonsUS,
        temperatureUnit.celsius,
        temperatureUnit.fahrenheit,
        temperatureUnit.kelvin,
      ]
  }
}
