import { useEffect, useState } from 'react'
import { GridItem, SimpleGrid } from '@chakra-ui/react'

import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'

import {
  ChannelSearchManyQuery,
  useBuildingGetIdQuery,
  useChannelSearchManyLazyQuery,
  useGetManyCompositeChannelLazyQuery,
} from '../../graphql/generated/graphql'
import { BuildingData } from '../../components/buildingData/buildingData'

export const WrappedBuildingData = () => {
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown_building'
  const breadcrumbLinks = [
    { text: 'Home', route: '/' },
    { text: 'Buildings', route: '/buildings' },
    { text: buildingSlug, route: `/buildings/${buildingSlug}` },
    { text: 'data', route: `/buildings/${buildingSlug}/data` },
  ]

  const [channels, setChannels] = useState<
    ChannelSearchManyQuery['channelSearchMany']
  >([])

  const { data: buildingData } = useBuildingGetIdQuery({
    variables: { where: { slug: buildingSlug } },
  })

  const [getChannelList, { data: channelList, loading: channelListLoading }] =
    useChannelSearchManyLazyQuery()

  const [getCombiList, { data: combiList, loading: combiListLoading }] =
    useGetManyCompositeChannelLazyQuery()

  useEffect(() => {
    if (channelList?.channelSearchMany && combiList?.getManyCompositeChannel) {
      setChannels(
        channelList?.channelSearchMany.map((channel) => ({
          ...channel,
          name:
            combiList.getManyCompositeChannel.find(
              (combi) => combi?.id && combi.id === channel?.id,
            )?.name ?? channel?.name,
        })),
      )
    }
  }, [channelList, combiList])

  useEffect(() => {
    if (buildingData?.buildingGetOne) {
      getChannelList({
        variables: {
          where: {
            buildingId: buildingData.buildingGetOne.id as string,
          },
        },
      })
      getCombiList({
        variables: {
          where: {
            buildingId: buildingData.buildingGetOne.id as string,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingData])

  return (
    <>
      <SimpleGrid columns={12}>
        <GridItem
          colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
          colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
          p={{ base: '5px', md: 0 }}
        >
          <Breadcrumbs links={breadcrumbLinks} />
          <BuildingData
            loading={channelListLoading || combiListLoading}
            channels={channels}
            buildingSlug={buildingSlug}
            buildingName={buildingData?.buildingGetOne?.name!}
            buildingId={buildingData?.buildingGetOne?.id!}
            channelListDataLoading={channelListLoading}
          />
        </GridItem>
      </SimpleGrid>
    </>
  )
}
