import {
  ChannelSearchManyQuery,
  CompaniesQuery,
  CompanyRelationship,
  TargetAlert,
  UserGetManyQuery,
} from '../../graphql/generated/graphql'
import { isCurrentUserEndUser } from '../helpers/basicFunc'

// Restructure the data according to the table

export type TableDataConstructType = {
  page: string
  data:
    | ChannelSearchManyQuery['channelSearchMany']
    | UserGetManyQuery['userGetMany']
    | CompaniesQuery['companies']
    | TargetAlert[]
    | CompanyRelationship[]
    | undefined
}

export function tableDataConstruct(
  modParams: TableDataConstructType,
): TableDataConstructType['data'] {
  const { data, page } = modParams
  if (!data) {
    return []
  }
  if (page === 'users') {
    let userData = data as UserGetManyQuery['userGetMany']
    return userData?.map((datum) => {
      return {
        id: datum?.id,
        picture: datum?.picture,
        firstName: datum?.firstName,
        lastName: datum?.lastName,
        email: datum?.email,
        role: datum?.role,
        companyId: datum?.companyId,
        company: datum?.companyId,
        slug: datum?.slug,
      }
    })
  } else if (page === 'companies') {
    return data as CompaniesQuery['companies']
  } else if (page === 'channels' && data) {
    let channelList = data as ChannelSearchManyQuery['channelSearchMany']
    return channelList
      ?.map((datum) => {
        return {
          id: datum?.id,
          name: datum?.name,
          // period: datum.period,
          parentId: datum?.parentId,
          reference: datum?.reference,
          buildingId: datum?.buildingId,
          channelType: datum?.channelType,
          incomingUnit: datum?.incomingUnit,
          channelIssues: datum?.channelIssues,
          measurementType: datum?.measurementType,
        }
      })
      .filter((datum) => datum.channelType !== 'shadow')
      .sort((a, b) => {
        const nameA = a.name?.toUpperCase()
        const nameB = b.name?.toUpperCase()

        if (a.channelType !== b.channelType) {
          return a.channelType !== 'virtual' ? -1 : 1
        }

        return nameA!.localeCompare(nameB!)
      })
  } else if (page === 'targets') {
    let targetsForTable: TargetAlert[] = data as TargetAlert[]

    targetsForTable = targetsForTable.map((target) => {
      return {
        name: target.name,
        daily: target.daily,
        weekly: target.weekly,
        monthly: target.monthly,
        yearly: target.yearly,
        triggerCondition: target.triggerCondition,
        channelId: target.channelId,
        isDashboardDefault: target.isDashboardDefault,
        severity: 'general',
      }
    })

    return targetsForTable
  }
}

// Set the order of the table headers here
export const tableHeaders: { [key: string]: string[] } = {
  usersEdit: ['picture', 'firstName', 'lastName', 'email', 'role', 'company'],
  'usersEdit-sm': ['lastName'],
  'usersEdit-md': ['picture', 'firstName', 'email', 'role'],
  companiesEdit: ['portfolioImage', 'name', 'logo'],
  'companiesEdit-sm': ['name'],
  'companiesEdit-md': ['portfolioImage', 'name', 'logo'],
  channels: ['name', 'measurementType', 'reference', 'channelType'],
  'channels-sm': ['name'],
  'channels-md': ['name', 'channelType'],
  targets: [
    'name',
    'daily',
    'weekly',
    'monthly',
    'yearly',
    'triggerCondition',
    // 'targetValue',
    'severity',
  ],
  companyRelation: [
    'companyId',
    'buildingName',
    'relationshipType',
    'companyReferenceName',
    'companyReference',
  ],
}

export const triggerConditionEnum = [
  { text: 'None', value: 'none' },
  { text: 'Above', value: 'aboveTarget' },
  { text: 'Below', value: 'belowTarget' },
]

export const severityEnum = [
  { text: 'Silenced', value: 'silenced' },
  { text: 'Digest', value: 'digest' },
  { text: 'General', value: 'general' },
  { text: 'Immediate', value: 'immediate' },
  { text: 'Critical', value: 'critical' },
]

export type TableFields = {
  fieldName: string
  editable: boolean
  fieldType?: string
  fieldOptions?: typeof severityEnum | typeof triggerConditionEnum
}

// to denote the code which can be edited and which property can be viewed for enduser
export const tableHeadings = {
  channels: [
    { fieldName: 'name', editable: true, displayName: 'Name' },
    {
      fieldName: 'measurementType',
      editable: !isCurrentUserEndUser(),
      displayName: 'Measurement Type',
    },
    {
      fieldName: 'reference',
      editable: !isCurrentUserEndUser(),
      displayName: 'Reference',
    },
    {
      fieldName: 'channelType',
      editable: false,
      displayName: 'Channel Type',
    },
    {
      fieldName: 'incomingUnit',
      editable: true,
      displayName: 'Incoming Unit Type',
    },
  ],
  'channels-sm': [
    {
      fieldName: 'name',
      editable: true,
      displayName: 'Name',
    },
  ],
  'channels-md': [
    {
      fieldName: 'name',
      editable: true,
      displayName: 'Name',
    },
    {
      fieldName: 'measurementType',
      editable: !isCurrentUserEndUser(),
      displayName: 'Measurement Type',
    },
  ],
  'channels-lg': [
    {
      fieldName: 'name',
      editable: true,
      displayName: 'Name',
    },
    {
      fieldName: 'measurementType',
      editable: !isCurrentUserEndUser(),
      displayName: 'Measurement Type',
    },
  ],
  targets: [
    { fieldName: 'name', editable: true },
    { fieldName: 'daily', editable: true },
    { fieldName: 'weekly', editable: true },
    { fieldName: 'monthly', editable: true },
    { fieldName: 'yearly', editable: true },
    {
      fieldName: 'triggerCondition',
      editable: true,
      fieldType: 'select',
      fieldOptions: triggerConditionEnum,
    },
  ],
} as const

export const periods = ['daily', 'weekly', 'monthly', 'yearly']
