import {
  Box,
  Center,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'

import Marquee from '../../components/marquee/marquee'
import BalanceMeter from '../../elements/balanceMeter/balanceMeter'

import Logo from '../../assets/logo-white.png'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'
import PrimaryCard from '../../components/oldDashboardComps/primaryCard'
import SecondaryCard from '../../components/oldDashboardComps/secondaryCard'
import { DashboardDataType, oldDashboardColors } from './dashboardWrapper'

type OldDashboardType = {
  dashboardData: DashboardDataType
}

export const OldDashboard = ({ dashboardData }: OldDashboardType) => {
  const [is4k, is2k] = useMediaQuery(mediaQueries)

  return (
    <Grid
      templateAreas={{
        base: ` "title"
                "main1"
                "main2"
                "main3"
                "secon"
                "renew"
                "marqu"`,
        md: ` "title title"
              "main1 main1"
              "main2 main2"
              "main3 main3"
              "secon secon"
              "renew renew"
              "marqu marqu"`,
        lg: ` "title title title title title title title title title title title title title title title"
              "main1 main1 main1 main1 main1 main2 main2 main2 main2 main2 main3 main3 main3 main3 main3"
              "secon secon secon secon secon secon secon secon secon renew renew renew renew renew renew"
              "marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu"`,
      }}
      gridTemplateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        lg: 'repeat(15, 1fr)',
      }}
      gridAutoRows={{
        base: 'min-content',
        '2xl': is4k
          ? ' 200px min-content 1fr 200px'
          : is2k
            ? ' 100px min-content 1fr 100px'
            : 'min-content',
      }}
      w={'100%'}
      minH={'100vh'}
      // h={'100vh'}
      p={{ base: 2, md: 3, '2xl': 4 }}
      gap={2}
      background={'grey.100'}
    >
      <GridItem
        area="title"
        height={'100%'}
        overflow={'hidden'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Image
          src={Logo}
          alt={'Logo.png'}
          width={{
            base: 70,
            md: 200,
            '2xl': is4k ? '450px' : is2k ? '230px' : 200,
          }}
        />
        <Text
          fontSize={{
            base: 20,
            md: 40,
            lg: 50,
            '2xl': is4k ? '100px' : is2k ? '60px' : 50,
          }}
          color={'grey.800'}
        >
          {dashboardData?.buildingName}
        </Text>
        <Image
          src={dashboardData?.occupierLogo}
          alt={'occupier-logo.svg'}
          width={{
            base: '80px',
            md: 180,
            xl: 250,
            '2xl': is4k ? '500px' : is2k ? '300px' : 250,
          }}
        />
      </GridItem>
      {dashboardData?.primary.map((datum, index: number) => (
        <GridItem
          area={`main${index + 1}`}
          position={'relative'}
          overflow={'hidden'}
          key={index}
        >
          <PrimaryCard
            measurement={datum.measurement}
            comparison={datum.comparison}
            type={datum.type}
            target={datum.target}
          />
        </GridItem>
      ))}
      <GridItem area="secon" overflow={'hidden'}>
        <SimpleGrid columns={{ lg: 3, md: 2, base: 1 }} gap={2} height={'100%'}>
          {dashboardData?.secondary.map((datum, i: number) => (
            <Box bg={'grey.300'} key={i}>
              <SecondaryCard
                type={datum.type}
                target={datum.target}
                measurement={datum.measurement}
                comparison={datum.comparison}
              />
            </Box>
          ))}
        </SimpleGrid>
      </GridItem>
      <GridItem
        area="renew"
        background={'grey.300'}
        padding={{ md: '1%', base: 0 }}
      >
        <SimpleGrid gap={2} columns={{ md: 2, base: 1 }} height={'100%'}>
          <Box>
            <SecondaryCard
              type={'PV'}
              target={dashboardData?.renewables.target}
              measurement={dashboardData?.renewables.measurement}
            />
          </Box>
          <Box bg={'grey.900'} p={'3%'} color={'white'}>
            <Center
              fontSize={{
                base: 18,
                md: 22,
                lg: 13,
                xl: 18,
                '2xl': is4k ? '50px' : is2k ? '30px' : 20,
              }}
            >
              Main Vs PV Balance
            </Center>
            <Center
              height={{
                base: 150,
                md: 140,
                '2xl': is4k ? '330px' : is2k ? '190px' : 140,
              }}
            >
              <BalanceMeter
                consumed={dashboardData?.currentConsumption}
                generated={dashboardData?.currentGeneration}
                colors={oldDashboardColors}
              />
            </Center>
            <Center
              mt={30}
              fontSize={{
                base: 14,
                md: 16,
                lg: 10,
                xl: 13,
                '2xl': is4k ? '34px' : is2k ? '24px' : 16,
              }}
            >
              During daylight/generating hours
            </Center>
          </Box>
          <Center>
            {/* <SecondaryCard
              type={'Battery'}
              target={dashboardData?.renewables.target}
              measurement={dashboardData?.renewables.measurement}
            /> */}
            <Text
              textAlign={'center'}
              marginBottom={5}
              fontSize={{
                base: 18,
                md: 20,
                lg: 11,
                xl: 15,
                '2xl': is4k ? '40px' : is2k ? '28px' : 20,
              }}
              color={'grey.800'}
              letterSpacing={'-0.5px'}
            >
              When the photovoltaic array is enabled we'll update this section
              with live generation data.
            </Text>
          </Center>
          <Box bg={'grey.500'} p={'3%'} color={'white'}>
            <Center
              fontSize={{
                base: 18,
                md: 22,
                lg: 13,
                xl: 18,
                '2xl': is4k ? '50px' : is2k ? '30px' : 20,
              }}
            >
              Lifetime Carbon Savings
            </Center>
            <Text
              align={'center'}
              fontSize={{
                base: 12,
                md: 14,
                lg: 8,
                xl: 12,
                '2xl': is4k ? '34px' : is2k ? '20px' : 12,
              }}
            >
              By reducing consumption and generating on the building we have
              saved
            </Text>
            <Box
              display={'flex'}
              alignItems={'baseline'}
              justifyContent={'center'}
            >
              <Text
                fontSize={{
                  base: 50,
                  md: 60,
                  lg: 35,
                  xl: 50,
                  '2xl': is4k ? '100px' : is2k ? '50px' : 50,
                }}
                fontWeight={'bold'}
              >
                {dashboardData?.lifetimeCarbonSavings}
              </Text>
              <Text
                fontSize={{
                  base: 38,
                  md: 42,
                  lg: 25,
                  xl: 36,
                  '2xl': is4k ? '60px' : is2k ? '30px' : 36,
                }}
              >
                <b>
                  {dashboardData?.lifetimeCarbonSavingsUnits.slice(0, -1)}
                  <sub>
                    {dashboardData?.lifetimeCarbonSavingsUnits.slice(-1)}
                  </sub>
                </b>
              </Text>
            </Box>
            <Center
              fontSize={{
                base: 14,
                md: 16,
                lg: 10,
                xl: 13,
                '2xl': is4k ? '34px' : is2k ? '30px' : 14,
              }}
            >
              Over the lifetime of this building
            </Center>
          </Box>
        </SimpleGrid>
      </GridItem>
      <GridItem
        area="marqu"
        style={{ position: 'relative' }}
        bg={'grey.300'}
        p={'5px'}
        overflow={'hidden'}
      >
        <Marquee
          textSpeed={0.2}
          phrase={[
            "We're monitoring your building to improve sustainable operations",
            "We're monitoring your building to improve sustainable operations",
            // "We're monitoring your building to improve sustainable operations",
          ]}
        />
      </GridItem>
    </Grid>
  )
}

export default OldDashboard
