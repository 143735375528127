export enum electricityUnit {
  kwh = 'electricityKwh',
  btu = 'electricityBtu',
  kj = 'electricityKj',
  carbonTonnesMetric = 'electricityCarbonTonnesMetric',
  carbonTonnesImperial = 'electricityCarbonTonnesImperial',
}
export const isElectricityUnit = (unit?: unknown): unit is electricityUnit => {
  if (Object.values(electricityUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum gasUnit {
  metersCubed = 'gasMetersCubed',
  feetCubed = 'gasFeetCubed',
  kwh = 'gasKwh',
  btu = 'gasBtu',
  kj = 'gasKj',
  carbonTonnesMetric = 'gasCarbonTonnesMetric',
  carbonTonnesImperial = 'gasCarbonTonnesImperial',
}
export const isGasUnit = (unit?: unknown): unit is gasUnit => {
  if (Object.values(gasUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum waterUnit {
  metersCubed = 'waterMetersCubed',
  feetCubed = 'waterFeetCubed',
  gallonsImperial = 'waterGallonsImperial',
  gallonsUS = 'waterGallonsUS',
  litres = 'waterLitres',
}
export const isWaterUnit = (unit?: unknown): unit is waterUnit => {
  if (Object.values(waterUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum airQualityUnit {
  ppm = 'ppm',
}
export const isAirQualityUnit = (unit?: unknown): unit is airQualityUnit => {
  if (Object.values(airQualityUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum temperatureUnit {
  celsius = 'celsius',
  fahrenheit = 'fahrenheit',
  kelvin = 'kelvin',
}
export const isTemperatureUnit = (unit?: unknown): unit is temperatureUnit => {
  if (Object.values(temperatureUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum areaUnit {
  squareMeters = 'squareMeters',
  squareFeet = 'squareFeet',
}
export const isAreaUnit = (unit?: unknown): unit is areaUnit => {
  if (Object.values(areaUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum booleanUnit {
  boolean = 'boolean',
}
export const isBooleanUnit = (unit?: unknown): unit is booleanUnit => {
  if (Object.values(booleanUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum unknownUnit {
  unknown = 'unknown',
}

export const isUnknownUnit = (unit?: unknown): unit is unknownUnit => {
  if (Object.values(unknownUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export type anyUnit =
  | gasUnit
  | areaUnit
  | waterUnit
  | booleanUnit
  | electricityUnit
  | temperatureUnit
  | airQualityUnit
  | unknownUnit

export const isUnit = (unit?: unknown): unit is anyUnit => {
  if (
    isElectricityUnit(unit) ||
    isGasUnit(unit) ||
    isWaterUnit(unit) ||
    isAirQualityUnit(unit) ||
    isTemperatureUnit(unit) ||
    isAreaUnit(unit) ||
    isBooleanUnit(unit) ||
    isUnknownUnit(unit)
  ) {
    return true
  }
  return false
}

type gasCaloriesUnit = gasUnit.kwh | gasUnit.btu | gasUnit.kj
export const isGasCaloriesUnit = (unit?: unknown): unit is gasCaloriesUnit => {
  if (unit === gasUnit.kwh || unit === gasUnit.btu || unit === gasUnit.kj) {
    return true
  }
  return false
}

type gasCarbonUnit = gasUnit.carbonTonnesMetric | gasUnit.carbonTonnesImperial
export const isGasCarbonUnit = (unit?: unknown): unit is gasCarbonUnit => {
  if (
    unit === gasUnit.carbonTonnesMetric ||
    unit === gasUnit.carbonTonnesImperial
  ) {
    return true
  }
  return false
}

type electricityCarbonUnit =
  | electricityUnit.carbonTonnesMetric
  | electricityUnit.carbonTonnesImperial
export const isElectricityCarbonUnit = (
  unit?: unknown,
): unit is electricityCarbonUnit => {
  if (
    unit === electricityUnit.carbonTonnesMetric ||
    unit === electricityUnit.carbonTonnesImperial
  ) {
    return true
  }
  return false
}

type complexUnit =
  | gasUnit.kwh
  | gasUnit.btu
  | gasUnit.kj
  | gasUnit.carbonTonnesMetric
  | gasUnit.carbonTonnesImperial
  | electricityUnit.carbonTonnesMetric
  | electricityUnit.carbonTonnesImperial

export const isComplexUnit = (unit?: unknown): unit is complexUnit => {
  if (
    unit === gasUnit.kwh ||
    unit === gasUnit.btu ||
    unit === gasUnit.kj ||
    unit === gasUnit.carbonTonnesMetric ||
    unit === gasUnit.carbonTonnesImperial ||
    unit === electricityUnit.carbonTonnesMetric ||
    unit === electricityUnit.carbonTonnesImperial
  ) {
    return true
  }
  return false
}

export type simpleUnit = Exclude<anyUnit, complexUnit>

export const isSimpleUnit = (unit?: unknown): unit is simpleUnit => {
  if (isUnit(unit) && !isComplexUnit(unit)) {
    return true
  }
  return false
}

export type incomingUnit = Exclude<simpleUnit, areaUnit>

export const isIncomingUnit = (unit?: unknown): unit is incomingUnit => {
  if (isSimpleUnit(unit) && !isAreaUnit(unit)) {
    return true
  }
  return false
}

export type channelUnit = Exclude<anyUnit, areaUnit>

export const isChannelUnit = (unit?: unknown): unit is channelUnit => {
  if (isUnit(unit) && !isAreaUnit(unit)) {
    return true
  }
  return false
}
