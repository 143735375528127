import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Link,
  LinkOverlay,
  useToast,
  //useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  BuildingGetOneQuery,
  useEmailBuildingOverviewLazyQuery,
} from '../../../graphql/generated/graphql'
import StaticGraph from '../../staticGraph/staticGraph'
import './detailedData.css'
import { icon } from '../../../components/icon/icon'
import { EmailPopover } from './emailPopover'
import { isCurrentUserEndUser } from '../../../utils/helpers/basicFunc'

const DetailedData = (where: {
  buildingDTO: BuildingGetOneQuery['buildingGetOne']
  companyId?: string
}) => {
  const { t } = useTranslation('building')
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown building'
  const { buildingDTO, companyId } = where

  const hrefChannelConfigFunction = () => {
    window.location.href = `/buildings/${buildingSlug}/channels`
  }
  const toast = useToast()

  const [sendEmailSummary, { loading: emailSummaryLoading }] =
    useEmailBuildingOverviewLazyQuery()

  const warningDisplay = () => {
    if (!buildingDTO) {
      return
    }
    if (!buildingDTO.lastSeen) {
      return (
        <>
          {icon('warn')}
          <i>Building awaiting setup</i>
        </>
      )
    }
    if (!buildingDTO.buildingTransmitting) {
      return (
        <Link href={`/buildings/${buildingSlug}/issues`} display="flex" gap="1">
          {icon('error')}
          <i>Building not transmitting</i>
        </Link>
      )
    }
    if (
      buildingDTO.channelIssues.some(
        (channel) =>
          channel.notTransmitting === true ||
          channel.notCumulative === true ||
          channel.includesZeroes,
      )
    ) {
      return (
        <Link href={`/buildings/${buildingSlug}/issues`} display="flex" gap="1">
          {icon('warn')}
          <i>Channel issues detected</i>
        </Link>
      )
    }
  }

  return (
    <Box
      py={{ base: 2, md: 6 }}
      px={{ base: '0.5rem', md: 6 }}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
    >
      <Box
        background={'white'}
        p={4}
        height={'100%'}
        display="flex"
        flexDirection="column"
        justifyContent={'space-between'}
      >
        <Box display={'flex'} justifyContent="space-between">
          <Heading as="h3" size="md">
            {t('detailedData')}
          </Heading>
          <Box gap={1} display="flex">
            {warningDisplay()}
            {/* <Link href={`/buildings/${buildingSlug}/issues`} display="flex" gap="1">
              <AlertTriangle color="white" enableBackground="true" fill='#fcba03' />
              <i>Problems detected</i>
            </Link> */}
          </Box>
        </Box>

        <Box display="flex" gap={1}>
          <LinkOverlay
            display={'flex'}
            href={`/buildings/${buildingSlug}/data`}
            h={'100%'}
            minH={'150px'}
            minWidth="50%"
            width="100%"
            className={'static-graph'}
          >
            <StaticGraph />
            <Center
              position={'absolute'}
              top={0}
              width={'100%'}
              height={'100%'}
              zIndex={1}
            >
              <Button
                fontSize={'lg'}
                colorScheme={'purple'}
                variant={'outline'}
              >
                Click here to view
              </Button>
            </Center>
          </LinkOverlay>
        </Box>

        <Box>
          <Button
            colorScheme={'purple.500'}
            width={'100%'}
            variant={'outline'}
            onClick={() => hrefChannelConfigFunction()}
          >
            {!isCurrentUserEndUser() ? t('channelConfig') : 'Channels'}
          </Button>
          <Flex gap={2}>
            <Button
              mt={2}
              colorScheme={'purple.500'}
              width={'100%'}
              isLoading={emailSummaryLoading}
              isDisabled={emailSummaryLoading}
              variant={'outline'}
              onClick={() =>
                sendEmailSummary({
                  variables: {
                    where: {
                      buildingId: buildingDTO?.id || '',
                      companyId: companyId || '',
                    },
                  },
                  onCompleted: (response) => {
                    toast({
                      title: t('emailSentResult'),
                      status: 'success',
                      position: 'top',
                      duration: 3000,
                      isClosable: true,
                    })
                  },
                  onError: (err) => {
                    toast({
                      title: err.message,
                      status: 'error',
                      position: 'top',
                      duration: 3000,
                      isClosable: true,
                    })
                  },
                })
              }
            >
              {t('emailSummary')}
            </Button>
            <EmailPopover buildingId={buildingDTO?.id || ''}>
              <Button
                mt={2}
                colorScheme={'purple.500'}
                width={'100%'}
                variant={'outline'}
              >
                {t('emailAllData')}
              </Button>
            </EmailPopover>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default DetailedData
