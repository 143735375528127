import {
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import Title from '../../elements/title'
import { ElectricityCarbon } from '../../components/unitManagement/electricityCarbon'
import { GasCalories } from '../../components/unitManagement/gasCalories'
import { GasCarbon } from '../../components/unitManagement/gasCarbon'

export const SystemAdministration = () => {
  const breadcrumbLinks = [
    { text: 'Home', route: '/' },
    { text: 'System Administration', route: '/system-administration' },
  ]

  return (
    <SimpleGrid columns={12}>
      <GridItem
        colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
        colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
        padding={'5px'}
      >
        <Breadcrumbs links={breadcrumbLinks} />

        <Title>System Administration</Title>

        <Box>
          <Heading marginTop={4} marginBottom={4}>
            Unit Management
          </Heading>
          <Tabs isFitted isLazy variant={'enclosed'}>
            <TabList>
              <Tab>Gas Calories</Tab>
              <Tab>Gas Carbon</Tab>
              <Tab>Electricity Carbon</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <GasCalories />
              </TabPanel>
              <TabPanel>
                <GasCarbon />
              </TabPanel>
              <TabPanel>
                <ElectricityCarbon />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </GridItem>
    </SimpleGrid>
  )
}
