import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  SimpleGrid,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBoolean,
  useBreakpoint,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import EditableTable from '../../components/editableTable/editableTable'
// import CombiDrawer from '../../components/combiDrawer/combiDrawer'
import FormDrawer, {
  FormDataType,
} from '../../components/formDrawer/formDrawer'
import HierarchyList from '../../components/hierarchyList/hierarchyList'
import InfoAlert from '../../components/infoAlert/infoAlert'
import Loader from '../../elements/loader'
import {
  GET_CHANNEL_SEARCH_MANY,
  GET_COMBI_CHANNELS,
  GET_RELATIONSHIP_TREE,
} from '../../graphql/API/queries'
import {
  Channel,
  ChannelCreateInput,
  ChannelRelationshipTreeUpdateMutationVariables,
  CompositeChannelRelationshipsQuery,
  MixedChannelInput,
  useBuildingGetIdQuery,
  useChannelCreateMutation,
  useChannelDeleteMutation,
  useChannelMeterRelationshipsLazyQuery,
  useChannelRelationshipTreeUpdateMutation,
  useChannelSearchManyLazyQuery,
  useCompositeChannelCreateMutation,
  useCompositeChannelRelationshipsLazyQuery,
  useCompositeChannelRelationshipUpdateMutation,
  useCompositeChannelUpdateMutation,
  useGetOneCompositeChannelLazyQuery,
} from '../../graphql/generated/graphql'
import { isCurrentUserEndUser } from '../../utils/helpers/basicFunc'
import {
  changeKeyRecursively,
  parentCombiForChannel,
  sortTree,
} from '../../utils/helpers/channelHelpers'
import {
  allDrawerInputs,
  mapKeyValue,
  PossibleDataTypes,
} from '../../utils/pageDrawerInputLists/inputLists'
import { tableDataConstruct } from '../../utils/tableDataConstruct/tableDataConstruct'

export type CombiWithAddedProps = {
  children?: CombiWithAddedProps[]
  channelType?: string
  composite?: boolean
  hasVirtual?: boolean
  id?: string
  name?: string
  channelName?: string
  buildingId?: string
  singleChild?: boolean
  compositeChannelId?: string
  isDashboardDefault?: boolean
}

export type AddedProps = {
  hasVirtual?: boolean
  singleChild?: boolean
}

export type RelWithAddedProps = AddedProps & Channel

export type InitialData = {
  rel?: RelWithAddedProps[] | undefined
  combi?: CombiWithAddedProps[] | undefined
  [key: string]: RelWithAddedProps[] | CombiWithAddedProps[] | undefined
}

// -----------------------------------------------------------------------PERFORMANCE ISSUES IN CHANNELS DUE TO STATE UPDATION------------------------------------------------------------
// ---------------------------------------------------------------- NEED TO USE REACT CONTEXT TO AVOID USING PROPS AND STATES TOO MUCH ---------------------------------------------------
// --------------------------------------------------------------USED THIS WAY AS THE REQUIREMENT WAS AGILE AND KEPT CHANGING AT THE TIME-------------------------------------------------

const Channels = () => {
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown_building'
  const havePermissions = !isCurrentUserEndUser()
  const breadcrumbLinks = [
    { text: 'Home', route: '/' },
    { text: 'Buildings', route: '/buildings' },
    { text: buildingSlug, route: `/buildings/${buildingSlug}` },
    { text: 'channels', route: `/channels` },
  ]
  const toast = useToast()
  const [tabIndex, setTabIndex] = useState(0)
  const [alertModalVisible, setAlertModalVisible] = useState(false)
  const [showRelationshipTree, setRelationshipTreeVisible] = useBoolean()
  const [isCombiDrawerOpen, setCombiDrawerVisible] = useState(false)
  const [combiEditDetails, setCombiEditDetails] =
    useState<CombiWithAddedProps>()
  const [isVirtualOpen, setVirtualDrawerVisible] = useState(false)
  const [combiChannels, setCombiChannels] = useState<CombiWithAddedProps[]>([])
  const [relationShipData, setRelationshipTree] =
    useState<RelWithAddedProps[]>()
  const [virtualParentDetails, setVirtualParentDetails] = useState<{
    parentId: string
    buildingId: string
  }>()
  const [initialData, setInitialData] = useState<InitialData>()
  const [isDeleteOpen, setModalVisible] = useState(false)
  const [clickedElement, setClickedElement] = useState<
    CombiWithAddedProps | RelWithAddedProps
  >()
  const [hasChangeOccurred, setChangeOccurred] = useState({
    rel: false,
    combi: false,
  })
  const breakpoint = useBreakpoint()

  const { data: buildingData } = useBuildingGetIdQuery({
      variables: { where: { slug: buildingSlug } },
    }),
    buildingId = buildingData?.buildingGetOne?.id
  const [getChannelList, { data: channelListData }] =
    useChannelSearchManyLazyQuery()
  const [getRelationshipTree, { data: relationshipTreeData }] =
    useChannelMeterRelationshipsLazyQuery()
  const [getCombiChannel] = useGetOneCompositeChannelLazyQuery()
  const [updateMeterRelationship, { loading: meterRelationLoader }] =
    useChannelRelationshipTreeUpdateMutation({
      onCompleted: () => {
        toast({
          title: 'Meter relationship reorder saved successfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setInitialData({ ...initialData, rel: relationShipData })
        setChangeOccurred({ ...hasChangeOccurred, rel: false })
      },
    })
  const [getCompositeChannels, { data: compositeChannels }] =
    useCompositeChannelRelationshipsLazyQuery()
  const [createCombiChannel, { loading: combiCreateLoader }] =
    useCompositeChannelCreateMutation({
      update: (cache, { data }) => {
        const combiData = cache.readQuery<CompositeChannelRelationshipsQuery>({
          query: GET_COMBI_CHANNELS,
          variables: {
            where: {
              buildingId,
              channelId: '',
            },
          },
        })
        if (combiData) {
          const { compositeChannelRelationships } = combiData
          cache.writeQuery({
            query: GET_COMBI_CHANNELS,
            variables: {
              where: {
                buildingId,
                channelId: '',
              },
            },
            data: {
              compositeChannelRelationships: [
                ...compositeChannelRelationships,
                data?.compositeChannelCreate,
              ],
            },
          })
        }
      },
      onCompleted: () => {
        setCombiDrawerVisible(false)
        setCombiEditDetails(undefined)
        toast({
          title: 'Composite channel created successfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  const [editCombiChannel, { loading: combiEditLoader }] = // the call should return composite key
    useCompositeChannelUpdateMutation({
      refetchQueries: [
        {
          query: GET_COMBI_CHANNELS,
          variables: {
            where: {
              buildingId,
              channelId: '',
            },
          },
        },
      ],
      onCompleted: () => {
        setCombiDrawerVisible(false)
        setCombiEditDetails(undefined)
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  const [updateCompositeMeterRelation, { loading: compositeRelationLoader }] =
    useCompositeChannelRelationshipUpdateMutation({
      onCompleted: () => {
        toast({
          title: 'Composite channel relationship saved successfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setInitialData({ ...initialData, combi: combiChannels })
        setChangeOccurred({ ...hasChangeOccurred, combi: false })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const [createVirtualChannel, { loading: virtualSubmitLoader }] =
    useChannelCreateMutation({
      refetchQueries: [
        {
          query: GET_RELATIONSHIP_TREE,
          variables: {
            where: {
              buildingId,
            },
          },
        },
        {
          query: GET_CHANNEL_SEARCH_MANY,
          variables: {
            where: {
              buildingId,
            },
          },
        },
      ],
      onCompleted: () => {
        setVirtualDrawerVisible(false)
        toast({
          title: 'Virtual channel created successfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const [deleteVirtualChannel] = useChannelDeleteMutation({
    refetchQueries: [
      {
        query: GET_CHANNEL_SEARCH_MANY,
        variables: {
          where: {
            buildingId,
          },
        },
      },
      {
        query: GET_COMBI_CHANNELS,
        variables: {
          where: {
            buildingId,
            channelId: '',
          },
        },
      },
      {
        query: GET_RELATIONSHIP_TREE,
        variables: {
          where: {
            buildingId,
          },
        },
      },
    ],
  })

  const [deleteCompositeChannel] = useChannelDeleteMutation({
    refetchQueries: [
      {
        query: GET_COMBI_CHANNELS,
        variables: {
          where: {
            buildingId,
            channelId: '',
          },
        },
      },
    ],
    onCompleted: () => {
      setVirtualDrawerVisible(false)
      toast({
        title: 'Composite channel deleted successfully',
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const handleTabChange = (index: number) => {
    const treeChanged = tabIndex
      ? hasChangeOccurred.combi
      : hasChangeOccurred.rel
    // setTabIndex(index)
    if (treeChanged) {
      setAlertModalVisible(true)
    } else {
      setTabIndex(index)
    }
  }

  const objectChanged = (changeOccurred: boolean, from: string) => {
    setChangeOccurred({ ...hasChangeOccurred, [from]: changeOccurred })
  }

  const deleteObject = (arg: CombiWithAddedProps | RelWithAddedProps) => {
    if (arg.id) {
      setModalVisible(true)
      setClickedElement(arg)
    }
  }

  useEffect(() => {
    if (relationshipTreeData?.channelMeterRelationships) {
      const sortedRelData = sortTree(
        relationshipTreeData.channelMeterRelationships as RelWithAddedProps[],
        'rel',
      )
      setInitialData((prevData) => ({ ...prevData, rel: sortedRelData }))
      setRelationshipTree(sortedRelData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipTreeData])

  useEffect(() => {
    if (compositeChannels?.compositeChannelRelationships) {
      let constructedCombiData = compositeChannels.compositeChannelRelationships
      let sortedCombiData = sortTree(
        constructedCombiData as CombiWithAddedProps[],
        'combi',
      )
      setInitialData(
        (prevData) => ({ ...prevData, combi: sortedCombiData }) as InitialData,
      )
      setCombiChannels(sortedCombiData as CombiWithAddedProps[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compositeChannels])

  useEffect(() => {
    if (buildingData?.buildingGetOne && buildingId) {
      getChannelList({
        variables: {
          where: {
            buildingId,
          },
        },
      })
      getRelationshipTree({
        variables: {
          where: {
            buildingId,
          },
        },
      })
      getCompositeChannels({
        variables: {
          where: {
            buildingId,
            channelId: '',
          },
        },
        onError: (err) => {
          toast({
            title: err.message,
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingData])

  // To handle the browser refresh and browser back
  useEffect(() => {
    const handleUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue =
        'The data will be lost if you leave the page, are you sure?'
    }
    if (hasChangeOccurred.rel || hasChangeOccurred.combi) {
      window.addEventListener('beforeunload', handleUnload)
    } else {
      window.removeEventListener('beforeunload', handleUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [hasChangeOccurred])

  const constructedData = tableDataConstruct({
    data: channelListData?.channelSearchMany,
    page: 'channels',
  })

  //virtual channels
  const onVirtualOpen = (item: { id: string; buildingId: string }) => {
    setVirtualParentDetails({ parentId: item.id, buildingId: item.buildingId })
    setVirtualDrawerVisible(true)
  }

  const onVirtualSubmit = (formData: FormDataType, from?: string) => {
    const virtualCreateData = {
      ...formData,
      ...virtualParentDetails,
      measurementType: 'independent',
      period: 'none',
      channelType: 'virtual',
      uploadLabel: virtualParentDetails?.parentId,
    } as ChannelCreateInput
    if (from === 'alertModal') {
      const requiredKeys = [
        'buildingId',
        'channelId',
        'children',
        'measurementType',
        'name',
        'period',
        'reference',
        'uploadLabel',
      ]
      updateMeterRelationship({
        variables: {
          data: changeKeyRecursively(
            relationShipData as RelWithAddedProps[],
            'id',
            'channelId',
            requiredKeys,
          ) as ChannelRelationshipTreeUpdateMutationVariables['data'],
        },
        onCompleted: () => {
          createVirtualChannel({
            variables: {
              data: virtualCreateData,
            },
          })
        },
      })
    } else {
      createVirtualChannel({
        variables: {
          data: virtualCreateData,
        },
      })
    }
  }
  const onVirtualClose = () => {
    setVirtualDrawerVisible(false)
  }
  //combi channels
  const onCombiDrawerClose = () => {
    setCombiEditDetails(undefined)
    setCombiDrawerVisible(false)
  }

  const onEditCombiDrawer = (
    item: CombiWithAddedProps & { children?: CombiWithAddedProps[] },
  ) => {
    getCombiChannel({
      fetchPolicy: 'no-cache',
      variables: { where: { channelId: item.id as string } },
      onCompleted: (data) => {
        const { getOneCompositeChannel: details } = data,
          restructuredData = {
            id: details.id!,
            period: details.period!,
            channelName: details.name!,
            channelList: details.channelList as string[],
            buildingId: details.buildingId!,
          }
        setCombiDrawerVisible(true)

        setCombiEditDetails(restructuredData)
      },
    })
  }

  const constructObjectRecursively = (
    combiChannels: CombiWithAddedProps[] & { children?: CombiWithAddedProps[] },
  ): CombiWithAddedProps[] => {
    return combiChannels.map((datum) => {
      return {
        id: datum.id,
        name: datum.name,
        composite: datum.composite,
        isDashboardDefault: datum.isDashboardDefault,
        children: datum?.children?.length
          ? constructObjectRecursively(datum.children)
          : [],
      }
    })
  }

  const onCombiDrawerSubmit = (data: FormDataType, from?: string) => {
    if (from === 'alertModal') {
      let combiSubmitDetails = constructObjectRecursively(combiChannels)
      updateCompositeMeterRelation({
        variables: { data: combiSubmitDetails as MixedChannelInput[] },
        onCompleted: () => {
          if (combiEditDetails?.id) {
            editCombiChannel({
              variables: {
                data: {
                  buildingId: combiEditDetails.buildingId,
                  name: data.channelName as string,
                  period: 'none',
                  channelId: combiEditDetails.id,
                  channelList: data.channelList as string[],
                },
              },
            })
          } else {
            createCombiChannel({
              variables: {
                data: {
                  buildingId: buildingData?.buildingGetOne?.id as string,
                  name: data.channelName as string,
                  period: 'none',
                },
              },
            })
          }
        },
      })
    } else if (combiEditDetails?.id) {
      editCombiChannel({
        variables: {
          data: {
            buildingId: combiEditDetails.buildingId,
            name: data.channelName as string,
            period: 'none',
            channelId: combiEditDetails.id,
            channelList: data.channelList as string[],
          },
        },
      })
    } else {
      createCombiChannel({
        variables: {
          data: {
            buildingId: buildingData?.buildingGetOne?.id as string,
            name: data.channelName as string,
            period: 'none',
          },
        },
      })
    }
  }

  const channelReorder = (params: {
    from: string
    channels: RelWithAddedProps[] | CombiWithAddedProps[]
  }) => {
    if (params.from === 'combi') {
      setCombiChannels(params.channels as CombiWithAddedProps[])
    } else {
      setRelationshipTree(params.channels as RelWithAddedProps[])
    }
  }

  // const onCancelSave = (tabIndex: number) => {
  //   if (!tabIndex) {
  //     setRelationShipTree(initialData.rel)
  //   } else {
  //     setCombiChannels(initialData.combi)
  //   }
  // }

  const onAlertSave = () => {
    const currentPage = tabIndex ? 'combi' : 'rel'
    onReorderSubmit(currentPage)
    setTabIndex(tabIndex ? 0 : 1)
    setAlertModalVisible(false)
  }

  const onReorderSubmit = (from: string) => {
    if (from === 'combi') {
      let combiSubmitDetails = constructObjectRecursively(combiChannels)
      updateCompositeMeterRelation({
        variables: { data: combiSubmitDetails as MixedChannelInput[] },
      })
    } else {
      const requiredKeys = [
        'buildingId',
        'channelId',
        'children',
        'measurementType',
        'name',
        'period',
        'reference',
        'uploadLabel',
      ]
      updateMeterRelationship({
        variables: {
          data: changeKeyRecursively(
            relationShipData as RelWithAddedProps[],
            'id',
            'channelId',
            requiredKeys,
          ) as ChannelRelationshipTreeUpdateMutationVariables['data'],
        },
      })
    }
  }
  if (channelListData && channelListData?.channelSearchMany?.length === 0) {
    return (
      <Center height={'100%'}>
        <Heading>No Data</Heading>
      </Center>
    )
  }
  const onDeleteModalClose = () => {
    setModalVisible(false)
  }

  const onDelete = () => {
    const data = { channelId: clickedElement?.id! }
    if (clickedElement?.channelType === 'virtual') {
      deleteVirtualChannel({ variables: { data } })
    } else {
      deleteCompositeChannel({
        variables: { data },
      })
    }
    setModalVisible(false)
  }

  return (
    <SimpleGrid columns={12} padding={{ base: '10px', md: 0 }}>
      <GridItem
        colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
        colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
      >
        <Breadcrumbs links={breadcrumbLinks} />
        {channelListData ? (
          <Tabs
            variant="line"
            index={tabIndex}
            isFitted
            onChange={handleTabChange}
            mt={{ base: '5px', md: 0 }}
          >
            <TabList borderBottom={'none'}>
              <Tab p={'1% 0'} fontSize={{ base: '16px', md: '20px' }}>
                Channels
              </Tab>
              <Tab p={'1% 0'} fontSize={{ base: '16px', md: '20px' }}>
                Composite Channels
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box
                  p={{ base: 0, md: '1%' }}
                  justifyContent={'space-between'}
                  display={'flex'}
                >
                  <Box>
                    <Heading fontSize={{ base: 'lg', lg: '3xl', xl: '4xl' }}>
                      {showRelationshipTree
                        ? 'Relationship Tree'
                        : 'Channel Table'}
                    </Heading>
                    {showRelationshipTree &&
                      !isCurrentUserEndUser() &&
                      breakpoint !== 'base' && (
                        <Text
                          as="i"
                          color={'#b5b5b5'}
                          fontSize={{ md: '14px', lg: '16px' }}
                        >
                          Drag and drop the channels
                        </Text>
                      )}
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'end'}
                  >
                    {showRelationshipTree &&
                      havePermissions &&
                      breakpoint !== 'base' && (
                        <Button
                          variant={'outline'}
                          colorScheme={'purple'}
                          onClick={() => onReorderSubmit('rel')}
                          mr={4}
                          isLoading={meterRelationLoader}
                          isDisabled={!hasChangeOccurred.rel}
                        >
                          Save
                        </Button>
                      )}
                    <FormControl
                      display="flex"
                      alignItems="center"
                      width={'unset'}
                    >
                      <FormLabel
                        htmlFor="relationship-tree-switch"
                        mb="0"
                        textAlign={'center'}
                        fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                      >
                        View Hierarchical Tree
                      </FormLabel>
                      <Switch
                        mt={'2px'}
                        id="relationship-tree-switch"
                        size={{ base: 'sm' }}
                        isChecked={showRelationshipTree}
                        onChange={setRelationshipTreeVisible.toggle}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {showRelationshipTree ? (
                  <HierarchyList
                    channels={sortTree(
                      relationShipData as RelWithAddedProps[],
                      'rel',
                    )}
                    from={'rel'}
                    onVirtualOpen={onVirtualOpen}
                    channelReorder={channelReorder}
                    initialData={initialData!}
                    objectChanged={objectChanged}
                    isOnDelete={deleteObject}
                  />
                ) : (
                  <>
                    {constructedData && buildingId && (
                      <EditableTable
                        data={constructedData}
                        from={'channels'}
                        buildingSlug={buildingSlug}
                        buildingId={buildingId}
                      />
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel p={0} py={2}>
                <Center
                  p={{ base: 0, md: '1%' }}
                  justifyContent={'space-between'}
                >
                  {breakpoint !== 'base' && (
                    <>
                      <Box>
                        <Heading
                          fontSize={{ base: 'lg', lg: '3xl', xl: '4xl' }}
                          mr={2}
                        >
                          Composite Channels
                        </Heading>
                        {!isCurrentUserEndUser() && (
                          <Text
                            as="i"
                            color={'#b5b5b5'}
                            fontSize={{ md: '14px', lg: '16px' }}
                          >
                            Drag and drop the composite channels
                          </Text>
                        )}
                      </Box>
                    </>
                  )}
                  {havePermissions && breakpoint !== 'base' && (
                    <Box display={'flex'}>
                      <Button
                        variant={'outline'}
                        mr={4}
                        colorScheme={'purple'}
                        onClick={() => onReorderSubmit('combi')}
                        isLoading={compositeRelationLoader}
                        isDisabled={!hasChangeOccurred.combi}
                      >
                        Save
                      </Button>

                      <Button
                        minW={'unset'}
                        onClick={() => setCombiDrawerVisible(true)}
                        colorScheme={'purple'}
                      >
                        + Create Composite Channel
                      </Button>
                    </Box>
                  )}
                </Center>
                <HierarchyList
                  channels={sortTree(
                    combiChannels as CombiWithAddedProps[],
                    'combi',
                  )}
                  from={'combi'}
                  objectChanged={objectChanged}
                  onEditCombiDrawer={onEditCombiDrawer}
                  channelReorder={channelReorder}
                  initialData={initialData!}
                  isOnDelete={deleteObject}
                />

                <InfoAlert
                  isOpen={isDeleteOpen}
                  onClose={onDeleteModalClose}
                  onSubmit={onDelete}
                  headerText={`Delete`}
                  bodyText={`Are you sure? You can't undo this action afterwards.`}
                  buttonText={`Delete`}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <Loader />
        )}
        <FormDrawer
          openedFrom={'Composite Channel'}
          isCreate={!combiEditDetails?.id}
          isOpen={isCombiDrawerOpen}
          onClose={onCombiDrawerClose}
          onDrawerFormSubmit={onCombiDrawerSubmit}
          loading={combiCreateLoader || combiEditLoader}
          inputElements={
            combiEditDetails?.id
              ? mapKeyValue({
                  page: 'combiChannelsEdit',
                  data: combiEditDetails as PossibleDataTypes,
                })
              : allDrawerInputs['combiChannels']
          }
          checkboxOptions={parentCombiForChannel(
            combiChannels,
            channelListData?.channelSearchMany,
          )}
          askSaveAlert={hasChangeOccurred.combi}
        />
        <FormDrawer
          openedFrom={'Virtual Channel'}
          isCreate={true}
          isOpen={isVirtualOpen}
          onClose={onVirtualClose}
          loading={virtualSubmitLoader}
          inputElements={allDrawerInputs['channels']}
          onDrawerFormSubmit={onVirtualSubmit}
          askSaveAlert={hasChangeOccurred.rel}
        />
        <InfoAlert
          isOpen={alertModalVisible}
          onClose={() => setAlertModalVisible(false)}
          onSubmit={onAlertSave}
          headerText={
            tabIndex
              ? 'Save Composite Channel relationship'
              : 'Save Relationship Tree?'
          }
          bodyText={
            'You need to save the tree before switching or tree changes will be lost'
          }
          buttonText={'Save and switch'}
          colorScheme={'blue'}
        />
      </GridItem>
    </SimpleGrid>
  )
}

export default Channels
