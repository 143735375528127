import { gql } from '@apollo/client'

export const GET_BUILDING_ID = gql`
  query BuildingGetId($where: BuildingWhereUniqueSearchOneInput!) {
    buildingGetOne(where: $where) {
      id
      name
    }
  }
`

export const GET_CHANNEL_SEARCH_MANY = gql`
  query ChannelSearchMany($where: ChannelSearchManyInput!) {
    channelSearchMany(where: $where) {
      id
      createdAt
      updatedAt
      name
      reference
      period
      incomingUnit
      measurementType
      buildingId
      parentId
      channelType
      channelIssues {
        lastSeen
        isImportant
        notTransmitting
        notCumulative
        includesZeroes
      }
    }
  }
`

export const GET_COMBI_CHANNELS = gql`
  query CompositeChannelRelationships($where: CompositeChannelGetManyInput!) {
    compositeChannelRelationships(where: $where) {
      name
      id
      composite
      isDashboardDefault
      children {
        name
        id
        composite
        isDashboardDefault
        children {
          name
          id
          composite
          isDashboardDefault
          children {
            name
            id
            composite
            isDashboardDefault
            children {
              name
              id
              composite
              isDashboardDefault
              children {
                name
                id
                composite
                isDashboardDefault
              }
            }
          }
        }
      }
    }
  }
`

export const GET_RELATIONSHIP_TREE = gql`
  query ChannelMeterRelationships($where: SearchChannelByBuildingIdInput!) {
    channelMeterRelationships(where: $where) {
      id
      createdAt
      updatedAt
      name
      reference
      period
      incomingUnit
      measurementType
      buildingId
      parentId
      channelType
      channelIssues {
        lastSeen
        isImportant
        notTransmitting
        notCumulative
        includesZeroes
      }
      children {
        id
        createdAt
        updatedAt
        name
        reference
        period
        measurementType
        buildingId
        parentId
        channelType
        channelIssues {
          lastSeen
          isImportant
          notCumulative
          includesZeroes
        }
        children {
          id
          createdAt
          updatedAt
          name
          reference
          period
          measurementType
          buildingId
          parentId
          channelType
          channelIssues {
            lastSeen
            isImportant
            notTransmitting
            notCumulative
            includesZeroes
          }
          children {
            id
            createdAt
            updatedAt
            name
            reference
            period
            measurementType
            buildingId
            parentId
            channelType
            channelIssues {
              lastSeen
              isImportant
              notTransmitting
              notCumulative
              includesZeroes
            }
            children {
              id
              createdAt
              updatedAt
              name
              reference
              period
              measurementType
              buildingId
              parentId
              channelType
              channelIssues {
                lastSeen
                isImportant
                notTransmitting
                notCumulative
                includesZeroes
              }
              children {
                id
                createdAt
                updatedAt
                name
                reference
                period
                measurementType
                buildingId
                parentId
                channelType
                channelIssues {
                  lastSeen
                  isImportant
                  notTransmitting
                  notCumulative
                  includesZeroes
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ONE_COMBI = gql`
  query GetOneCompositeChannel($where: CompositeChannelGetOneInput!) {
    getOneCompositeChannel(where: $where) {
      id
      createdAt
      updatedAt
      name
      buildingId
      channelList
      period
      shadowId
      isDashboardDefault
    }
  }
`

export const GET_TARGETS = gql`
  query Targets($where: TargetGetManyInput!) {
    targets(where: $where) {
      id
      createdAt
      updatedAt
      name
      period
      benchmark
      channelId
      targetValue
      triggerCondition
      nextExpectedTime
      lastComparisonTime
      lastComparisonValue
    }
  }
`

export const GET_ALERTS = gql`
  query Alerts($where: AlertGetManyInput!) {
    alerts(where: $where) {
      id
      createdAt
      updatedAt
      userId
      targetId
      destination
      messageType
      severity
    }
  }
`
export const GET_BUILDINGS = gql`
  query BuildingGetMany($where: BuildingWhereUniqueSearchManyInput!) {
    buildingGetMany(where: $where) {
      id
      name
      defaultName
      projectRef
      slug
      picture
      thumb
      epcRating
      epcUrl
      breeamRating
      breeamScore
      constructionYear
      floorArea
      hasFileServer
      hasDataServer
      dashboardPeriod
      dashboardStyle
      lastSeen
      buildingTransmitting
      channelIssues {
        channelId
        lastSeen
        isImportant
        notTransmitting
        notCumulative
        includesZeroes
      }
      mqttInfo {
        mqttUsername
        mqttPassword
      }
      uploadConfig {
        ... on FtpConfig {
          id
          buildingId
          parser
          connectionType
        }
        ... on MqttConfig {
          id
          buildingId
          protocol
          username
          password
          host
          port
          topic
          useDefaultServer
          connectionType
        }
      }
      companyRelationships {
        companyId
        companyName
        buildingName
        companyReference
        companyReferenceName
        relationshipType
      }
      address {
        buildingId
        companyId
        firstLine
        secondLine
        city
        region
        country
        postcode
        latitude
        longitude
        gasRegion
      }
      portfolio {
        renewables {
          measurement
          target
        }
        waterUsage {
          measurement
          target
        }
        carbonDioxide {
          measurement
          target
        }
        wholeBuilding {
          measurement
          target
        }
      }
    }
  }
`

export const GET_COMPANY = gql`
  query Company($where: CompanyWhereUnique!) {
    company(where: $where) {
      name
      portfolioImage
      slug
      logo
    }
  }
`

export const ME_QUERY = gql`
  query Me {
    me {
      token
    }
  }
`
export const RESET_EMAIL_TOEKN_QUERY = gql`
  query checkResetToken($where: String!) {
    checkResetToken(where: $where) {
      isValid
      email
    }
  }
`
export const GET_BUILDING = gql`
  query BuildingGetOne($where: BuildingWhereUniqueSearchOneInput!) {
    buildingGetOne(where: $where) {
      id
      name
      defaultName
      projectRef
      slug
      picture
      thumb
      epcRating
      epcUrl
      breeamRating
      breeamScore
      constructionYear
      floorArea
      hasFileServer
      hasDataServer
      dashboardPeriod
      dashboardStyle
      lastSeen
      buildingTransmitting
      tryMessage
      infoMessage
      channelIssues {
        channelId
        lastSeen
        isImportant
        notTransmitting
        notCumulative
        includesZeroes
      }
      mqttInfo {
        mqttUsername
        mqttPassword
      }
      uploadConfig {
        ... on FtpConfig {
          id
          buildingId
          parser
          connectionType
        }
        ... on MqttConfig {
          id
          buildingId
          protocol
          username
          password
          host
          port
          topic
          useDefaultServer
          connectionType
        }
      }
      companyRelationships {
        companyId
        buildingId
        companyName
        buildingName
        companyReference
        companyReferenceName
        relationshipType
      }
      address {
        buildingId
        companyId
        firstLine
        secondLine
        city
        region
        country
        postcode
        latitude
        longitude
        gasRegion
      }
      portfolio {
        renewables {
          measurement
          target
          comment
          comparison
        }
        waterUsage {
          measurement
          target
          comment
          comparison
        }
        carbonDioxide {
          measurement
          target
          comment
          comparison
        }
        wholeBuilding {
          measurement
          target
          comment
          comparison
        }
        heatingEnergy {
          measurement
          target
          comment
          comparison
        }
        coolingEnergy {
          measurement
          target
          comment
          comparison
        }
        processEnergy {
          measurement
          target
          comment
          comparison
        }
        socketPower {
          measurement
          target
          comment
          comparison
        }
        lightingEnergy {
          measurement
          target
          comment
          comparison
        }
        ventilation {
          measurement
          target
          comment
          comparison
        }
      }
    }
  }
`

export const GET_COMPANIES = gql`
  query Companies($where: CompanyWhereSearch!) {
    companies(where: $where) {
      id
      createdAt
      updatedAt
      name
      slug
      portfolioImage
      logo
      primaryColor
      secondaryColor
      fontColor
      userIds
    }
  }
`

export const GET_CHANNEL_DATA = gql`
  query DataGetMany($where: DataInput!) {
    dataGetMany(where: $where) {
      id
      name
      unit
      data {
        id
        channelId
        timestamp
        value
        status
      }
    }
  }
`

export const GET_DASHBOARD_SLUG = gql`
  query Dashboard($where: DashboardInput!) {
    dashboard(where: $where) {
      id
      createdAt
      updatedAt
      buildingId
      buildingName
      occupierLogo
      dashboardStyle
      renewables {
        measurement
        target
        comment
        comparison
      }
      carbonDioxide {
        measurement
        target
        comment
        comparison
      }
      waterUsage {
        measurement
        target
        comment
        comparison
      }
      wholeBuilding {
        measurement
        target
        comment
        comparison
      }
      ventilation {
        measurement
        target
        comment
        comparison
      }
      coolingEnergy {
        measurement
        target
        comment
        comparison
      }
      heatingEnergy {
        measurement
        target
        comment
        comparison
      }
      lightingEnergy {
        measurement
        target
        comment
        comparison
      }
      socketPower {
        measurement
        target
        comment
        comparison
      }
      processEnergy {
        measurement
        target
        comment
        comparison
      }
      period
      dashboardColors {
        primaryColor
        secondaryColor
        fontColor
      }
      tryMessage
      infoMessage
      currentConsumption
      currentGeneration
      lifetimeCarbonSavings
      lifetimeCarbonSavingsUnits
      address {
        companyId
        city
        postcode
        latitude
        longitude
      }
      weatherAPI {
        key
        host
      }
      transportAPI {
        id
        key
      }
    }
  }
`
export const GET_USERS = gql`
  query UserGetMany($where: UserGetManyInput!) {
    userGetMany(where: $where) {
      id
      createdAt
      updatedAt
      companyId
      email
      firstName
      lastName
      slug
      picture
      role
      customPermissions
    }
  }
`
export const GET_COMPANIES_LIST = gql`
  query CompanyList($where: CompanyWhereSearch!) {
    companies(where: $where) {
      id
      name
    }
  }
`

export const EMAIL_BUILDING_OVERVIEW = gql`
  query EmailBuildingOverview($where: EmailBuildingOverviewInput!) {
    emailBuildingOverview(where: $where) {
      result
    }
  }
`

export const EMAIL_FULL_BUILDING = gql`
  query EmailFullBuilding($where: EmailFullBuildingInput!) {
    emailFullBuilding(where: $where) {
      result
    }
  }
`

export const GET_COMBI_CHANNELS_LIST = gql`
  query GetManyCompositeChannel($where: CompositeChannelGetManyInput!) {
    getManyCompositeChannel(where: $where) {
      id
      createdAt
      updatedAt
      name
      buildingId
      channelList
      period
      shadowId
    }
  }
`

export const GET_INSPECTOR = gql`
  query InspectorGetOne($where: InspectorGetOneInput!) {
    inspectorGetOne(where: $where) {
      id
      createdAt
      updatedAt
      buildingId
      buildingLastSeen
      buildingTransmitting
      channels {
        lastSeen
        channelId
        isImportant
        notCumulative
        includesZeroes
        notTransmitting
      }
    }
  }
`
export const GET_TARGET_ALERTS = gql`
  query TargetAlertsGetMany($where: TargetGetManyInput!) {
    targetAlertsGetMany(where: $where) {
      name
      benchmark
      channelId
      triggerCondition
      isDashboardDefault
      severity
      daily {
        targetId
        targetValue
        alertId
      }
      weekly {
        targetId
        targetValue
        alertId
      }
      monthly {
        targetId
        targetValue
        alertId
      }
      yearly {
        targetId
        targetValue
        alertId
      }
    }
  }
`

export const GET_GAS_CALORIES = gql`
  query GasCaloriesGetAll {
    gasCaloriesGetAll {
      id
      country
      region
      date
      value
    }
  }
`

export const GET_GAS_CARBON = gql`
  query GasCarbonGetAll {
    gasCarbonGetAll {
      id
      country
      date
      value
    }
  }
`

export const GET_ELECTRICITY_CARBON = gql`
  query ElectricityCarbonGetAll {
    electricityCarbonGetAll {
      id
      country
      date
      value
    }
  }
`

export const GET_DATA_IN_BINS = gql`
  query DataGetManyInBins($where: DataInBinsInput!) {
    dataGetManyInBins(where: $where) {
      date
      bins {
        id
        timestamp
        value
        status
        channelId
      }
    }
  }
`
