import {
  useToast,
  Center,
  Box,
  Button,
  Skeleton,
  Text,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Tooltip,
  Select,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import {
  ChannelSearchManyQuery,
  DataGetManyQuery,
  useCheckBuildingDataMutation,
  useDataGetManyInBinsLazyQuery,
  useDataGetManyLazyQuery,
} from '../../graphql/generated/graphql'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import BrushChart from '../../elements/graphPlots/brushChart'
import Legend, { LegendChannel } from '../../elements/graphPlots/Legend'
import { graphColors } from '../../utils/colorMaps/tightMap'
import { ChevronDownIcon } from '@chakra-ui/icons'
import domtoimage from 'dom-to-image'

import {
  convertJsonToCsv,
  isCurrentUserStaff,
} from '../../utils/helpers/basicFunc'
import ChannelListTable from './channelListTable'
import { FilterData } from './filterData'
import { useAuth } from '../../hooks/use-auth'
import HeatMap from '../../elements/graphPlots/heatmap'
import {
  channelUnit,
  electricityUnit,
  gasUnit,
  isChannelUnit,
  isUnit,
  temperatureUnit,
  unknownUnit,
  waterUnit,
} from '../../utils/unitMaps/unitEnums'
import { getCategory, unitCategory } from '../../utils/unitMaps/unitCategories'

export type DataQueryParams = {
  [unitCategory.unknown]?: unknownUnit
  [unitCategory.electricity]?: electricityUnit
  [unitCategory.temperature]?: temperatureUnit
  [unitCategory.water]?: waterUnit
  [unitCategory.gas]?: gasUnit
  period?: string
  startTime?: Date
  endTime?: Date
}

export type SelectedChannel = {
  id: string
  name: string
  incomingUnit: channelUnit
}

export const isSelectedChannelType = (
  channel: unknown,
): channel is SelectedChannel => {
  if (
    (channel as SelectedChannel)?.id &&
    (channel as SelectedChannel)?.name &&
    isChannelUnit((channel as SelectedChannel)?.incomingUnit)
  ) {
    return true
  }
  return false
}

export type BuildingDataProps = {
  channels: ChannelSearchManyQuery['channelSearchMany']
  buildingName: string | undefined
  buildingSlug: string
  buildingId: string
  loading: boolean
  channelListDataLoading: boolean
}

export const BuildingData = ({
  channels,
  buildingName,
  buildingSlug,
  buildingId,
  loading = false,
  channelListDataLoading,
}: BuildingDataProps) => {
  const havePermissions = useAuth().user.isStaff ?? isCurrentUserStaff()
  const toast = useToast()

  const [selectedChannels, setSelectedChannels] = useState<SelectedChannel[]>(
    [],
  )
  const [selectedChannelData, setSelectedChannelData] = useState<
    DataGetManyQuery[]
  >([])
  const [legendChannels, setLegendChannels] = useState<LegendChannel[]>([])
  const [params, setParams] = useState<DataQueryParams>({})
  const [graphType, setGraphType] = useState<'line' | 'heatmap'>('line')
  const graphRef = useRef(null)

  let startTime =
      params?.startTime && isNaN(params.startTime.getTime())
        ? undefined
        : params.startTime,
    endTime =
      params?.endTime && isNaN(params.endTime.getTime())
        ? undefined
        : params.endTime
  const urlParams = new URLSearchParams(window.location.search)

  let [getChannelData, { loading: channelGraphDataLoading }] =
    useDataGetManyLazyQuery()

  const [getChannelDataInBins, { data: channelDataInBins }] =
    useDataGetManyInBinsLazyQuery()
  // { fetchPolicy: 'no-cache' },

  useEffect(() => {
    if (channels?.length) {
      const channel = channels.find(
        (channel) => channel?.id === urlParams.get('channelId'),
      )
      if (isSelectedChannelType(channel)) {
        setSelectedChannels([
          {
            id: channel.id,
            incomingUnit: channel.incomingUnit,
            name: channel.name,
          },
        ])
      } else {
        const channel = channels[0]
        if (isSelectedChannelType(channel)) {
          setSelectedChannels([
            {
              id: channel.id,
              incomingUnit: channel.incomingUnit,
              name: channel.name,
            },
          ])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels])

  useEffect(() => {
    if (graphType === 'line') {
      fetchChannelDataFromState()
    } else {
      const channel = selectedChannels[selectedChannels.length - 1]
      const category: unitCategory | undefined = getCategory(
        channel.incomingUnit,
      )
      const unit =
        category === unitCategory.airQuality ||
        category === unitCategory.boolean ||
        category === unitCategory.area
          ? undefined
          : params[category]
      getChannelDataInBins({
        variables: {
          where: {
            channelId: channel.id,
            startTime,
            endTime,
            unit,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannels, params])

  const fetchChannelDataFromState = () => {
    selectedChannels.forEach(async (channel) => {
      fetchChannelData(channel.id)
    })
  }

  const onChannelSelect = (id: string) => {
    if (graphType === 'line') {
      if (!selectedChannels.find((channel) => channel.id === id)) {
        const channel = channels.find((channel) => channel?.id === id)
        if (selectedChannels.length <= 4 && isSelectedChannelType(channel)) {
          setSelectedChannels([
            ...selectedChannels,
            {
              id: channel.id,
              name: channel.name,
              incomingUnit: channel.incomingUnit,
            },
          ])
        } else {
          toast({
            title: 'Can only select 5 channels at a time',
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
        }
      } else if (selectedChannels.length) {
        let afterRemovedChannelData: DataGetManyQuery[] = selectedChannelData,
          removedChannelId = id,
          afterRemovedChannelList = selectedChannels.filter(
            (channel) => channel.id !== id,
          )
        afterRemovedChannelData = afterRemovedChannelData.filter(
          (channelData) => channelData.dataGetMany.id !== removedChannelId,
        )

        let channelLegends: LegendChannel[] = []
        afterRemovedChannelData.forEach((channelData) => {
          let channel = channels?.find(
            (channel) => channel?.id === channelData?.dataGetMany.id,
          )
          if (
            channel &&
            channel.id &&
            channel.name &&
            channelData?.dataGetMany.unit
          ) {
            channelLegends.push({
              id: channel.id,
              name: channel.name,
              unit: isUnit(channelData?.dataGetMany.unit)
                ? channelData?.dataGetMany.unit
                : unknownUnit.unknown,
            })
          }
        })
        setLegendChannels(channelLegends)
        setSelectedChannelData(afterRemovedChannelData)
        setSelectedChannels(afterRemovedChannelList)
      }
    } else {
      const channel = channels.find((channel) => channel?.id === id)
      if (isSelectedChannelType(channel)) {
        setSelectedChannels([channel])
        getChannelDataInBins({
          variables: { where: { channelId: channel.id } },
        })
      } else {
        setSelectedChannels([])
      }
    }
  }

  const fetchChannelData = async (id: string) => {
    try {
      const channel = selectedChannels.find((channel) => channel.id === id)
      if (!channel) {
        throw Error(`tried to fetch data for unselected channel`)
      }
      const category = getCategory(channel.incomingUnit)
      const unit =
        category === unitCategory.airQuality ||
        category === unitCategory.boolean ||
        category === unitCategory.area
          ? undefined
          : params[category]
      const { data } = await getChannelData({
        variables: {
          where: {
            chanId: id,
            period: params.period ? params.period : 'none',
            startTime,
            endTime,
            unit,
          },
        },
      })

      const dataAlreadyExists = selectedChannelData.some(
        (existingData) => existingData.dataGetMany.id === data?.dataGetMany.id,
      )

      const isUpdated = selectedChannelData.some((existingData) =>
        existingData.dataGetMany.data.some((item) => {
          const newData = data?.dataGetMany
          const previousData = existingData.dataGetMany
          const startChanged =
            previousData.data[0]?.timestamp !== newData?.data[0]?.timestamp
          const endChanged =
            previousData.data[previousData.data.length - 1]?.timestamp !==
            newData?.data[previousData.data.length - 1]?.timestamp
          const unitChanged = previousData.unit !== newData?.unit

          return (
            item?.channelId === newData?.id &&
            (startChanged || endChanged || unitChanged)
          )
        }),
      )

      if (isUpdated) {
        // if the data is already present, update the state
        setSelectedChannelData((previousQueries) => {
          const updatedChannelData = previousQueries.map(
            (previousQueryData) => {
              return previousQueryData.dataGetMany.id === data?.dataGetMany.id
                ? (data as DataGetManyQuery)
                : previousQueryData
            },
          )

          let newLegendChannels: LegendChannel[] = legendChannels
          for (const rawQuery of updatedChannelData) {
            const query = rawQuery?.dataGetMany
            const unit =
              query && isUnit(query?.unit) ? query.unit : unknownUnit.unknown

            newLegendChannels = newLegendChannels.map((legendChannel) => {
              if (legendChannel.id === query?.id) {
                return {
                  id: legendChannel.id,
                  name: legendChannel.name,
                  unit: unit,
                }
              }
              return legendChannel
            })
          }
          setLegendChannels(newLegendChannels)

          return updatedChannelData
        })
      } else if (!dataAlreadyExists) {
        // If the data doesn't exist, update the state
        for (const rawQuery of [...selectedChannelData, data]) {
          const query = rawQuery?.dataGetMany
          const unit =
            query && isUnit(query?.unit) ? query.unit : unknownUnit.unknown
          if (query?.id && query?.name && query?.unit) {
            setLegendChannels([
              ...legendChannels,
              {
                id: query.id,
                name:
                  channels.find((channel) => channel?.id === query.id)?.name ??
                  query.name,
                unit: unit,
              },
            ])
          }
        }

        setSelectedChannelData((previousQueries) => [
          ...previousQueries,
          data as DataGetManyQuery,
        ])
      }
    } catch (error) {
      console.error(`Error fetching data for channel ${id}:`, error)
    }
  }

  const setFilterValues = (data: DataQueryParams) => {
    setParams(data)
  }

  const handleJpegConversion = () => {
    const node = graphRef?.current
    domtoimage
      .toJpeg(node!)
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = 'converted_image.png'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.error('Error converting to image:', error)
      })
  }

  const [checkBuilding] = useCheckBuildingDataMutation()
  const refreshBuilding = async () => {
    if (!buildingId) {
      return
    }

    checkBuilding({
      variables: {
        data: {
          buildingId,
        },
      },
      onCompleted: () => {
        toast({
          title: buildingSlug
            ? `Check in progress for building ${buildingSlug}`
            : `Check in progress for building`,
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const onGraphChange = (graphType: 'line' | 'heatmap') => {
    setGraphType(graphType)
    if (graphType === 'heatmap' && selectedChannels.length) {
      if (selectedChannels.length > 1) {
        toast({
          title: 'Have selected the latest channel data',
          description:
            "The heatmap can only show one channel's data at a time.",
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
      }
      setSelectedChannels([selectedChannels[selectedChannels.length - 1]])
      getChannelDataInBins({
        variables: {
          where: {
            channelId: selectedChannels[selectedChannels.length - 1].id,
          },
        },
      })
    }
  }

  return (
    <Box>
      <Box>
        <Skeleton isLoaded={!channelListDataLoading}>
          <Text fontSize={{ base: 22, md: 40 }} color={'purple.500'}>
            Detailed Data of {buildingName}
          </Text>
        </Skeleton>
      </Box>

      <Box p={{ base: '20px', lg: '30px' }} border={'1px solid #cbc7c7'}>
        <Center mb={'15px'} justifyContent={'space-between'}>
          <Select
            defaultValue={'line'}
            onChange={(e) =>
              (e.target.value === 'line' || e.target.value === 'heatmap') &&
              onGraphChange(e.target.value)
            }
            w={'30%'}
          >
            <option value="line">Line Chart</option>
            <option value="heatmap">Heatmap</option>
          </Select>
          <Center>
            <FilterData
              setFilterValues={setFilterValues}
              filterValues={params}
              loading={channelGraphDataLoading}
              graphType={graphType}
              selectedChannels={selectedChannels}
            />
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() =>
                    convertJsonToCsv({
                      datasets: selectedChannelData,
                      buildingName: buildingName ? buildingName : '',
                      channelListData: channels,
                    })
                  }
                >
                  Download selected channels data
                </MenuItem>
                <MenuItem onClick={handleJpegConversion}>
                  Download current graph as JPEG
                </MenuItem>
                {/* <MenuItem>Download all channel data</MenuItem> */}
                {havePermissions && (
                  <Tooltip
                    hasArrow
                    placement="left"
                    label="This may take some time to complete"
                  >
                    <MenuItem onClick={refreshBuilding}>
                      Check and Correct Building Data
                    </MenuItem>
                  </Tooltip>
                )}
              </MenuList>
            </Menu>
          </Center>
        </Center>
        <Box display={'flex'} flexDir={'column'} w={'100%'}>
          {loading || channelGraphDataLoading ? (
            <Skeleton minHeight={`calc(100vh - 400px)`} />
          ) : !(channels?.length && selectedChannelData.length) ? (
            <Center minHeight={`calc(100vh - 400px)`}>
              <Heading>No Data</Heading>
            </Center>
          ) : (
            <Box
              display={'flex'}
              bg={'white'}
              alignItems={'center'}
              flexDirection={{ base: 'column', lg: 'column' }}
              ref={graphRef}
              flexGrow={1}
            >
              <Box
                minW={'100%'}
                minH={`500px`}
                h={{ base: 500, lg: `calc(100vh - 40vh)` }}
              >
                <ParentSize>
                  {({ width, height }) =>
                    graphType === 'heatmap' ? (
                      <HeatMap
                        width={width}
                        height={height}
                        channelDataInBins={
                          channelDataInBins &&
                          channelDataInBins.dataGetManyInBins
                        }
                      />
                    ) : (
                      <BrushChart
                        width={width}
                        height={height}
                        selectedChannelData={selectedChannelData}
                        filterParams={params}
                      />
                    )
                  }
                </ParentSize>
              </Box>
              {graphType === 'line' && (
                <Box mb={2}>
                  <Legend
                    legendChannels={legendChannels}
                    graphColors={graphColors}
                    size={12}
                  />
                </Box>
              )}
            </Box>
          )}
          <Divider />
          <ChannelListTable
            onChannelSelect={onChannelSelect}
            channels={channels}
            selectedChannels={selectedChannels.map((channel) => channel.id)}
            buildingSlug={buildingSlug}
          />
        </Box>
      </Box>
    </Box>
  )
}
