import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
} from '@chakra-ui/react'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import FormElements, {
  RadioOptionProps,
  SelectOptionProps,
} from '../../elements/formElements/formElements'
import { slugify } from '../../utils/helpers/basicFunc'
import { PossibleDataTypes } from '../../utils/pageDrawerInputLists/inputLists'
import InfoAlert from '../infoAlert/infoAlert'

export type DrawerInputsProps = {
  type: string
  required: boolean
  name: string
  label: string
  value:
    | string
    | number
    | (string | number)[]
    | boolean
    | PossibleDataTypes
    | PossibleDataTypes[]
    | undefined
  selectOptions?: SelectOptionProps[]
  radioOptions?: RadioOptionProps[]
  helperText?: string
}

export type CheckboxOptions = SelectOptionProps & { parentNames?: string[] }

type FormDrawerProps = {
  isOpen: boolean
  onClose: () => void
  onDrawerFormSubmit: (formData: FormDataType, from?: string) => void
  inputElements: DrawerInputsProps[]
  isCreate: boolean
  openedFrom: string
  loading?: boolean
  selectOptions?: SelectOptionProps[]
  checkboxOptions?: CheckboxOptions[]
  askSaveAlert?: boolean
  isSlug?: boolean
}

export type FormDataType = {
  [key: string]:
    | string
    | number
    | (string | number)[]
    | boolean
    | PossibleDataTypes
    | PossibleDataTypes[]
    | undefined
}

function FormDrawer({
  isOpen,
  onClose,
  inputElements,
  onDrawerFormSubmit,
  isCreate,
  openedFrom,
  loading,
  selectOptions,
  checkboxOptions,
  askSaveAlert,
  isSlug = false,
}: FormDrawerProps) {
  const [formData, setFormData] = useState<FormDataType>({})
  const [alertModalVisible, setAlertModalVisible] = useState(false)
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (askSaveAlert) {
      setAlertModalVisible(true)
    } else {
      onDrawerFormSubmit(formData)
    }
  }

  useEffect(() => {
    // This filters out falsy values from the inputElements, then sets it as form data
    let initialFormData = inputElements.reduce(
      (result: FormDataType, { value, name }) => {
        if (result) {
          result[name] = value
        }
        return result
      },
      {},
    )
    setFormData(initialFormData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onFormChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | (string | number)[]
      | string,
    name?: string,
  ) => {
    if (Array.isArray(event)) {
      setFormData((prevFormData: FormDataType) => ({
        ...prevFormData,
        channelList: event,
      }))
    } else if (typeof event === 'string') {
      if (name) {
        setFormData((prevFormData: FormDataType) => ({
          ...prevFormData,
          [name]: event,
        }))
      }
    } else {
      const { name, value } = event.target
      setFormData((prevFormData: FormDataType) => ({
        ...prevFormData,
        [name]: value,
      }))
      if (isSlug && name === 'defaultName') {
        setFormData((prevFormData: FormDataType) => ({
          ...prevFormData,
          name: value,
          slug: slugify(value),
        }))
      }
    }
  }

  const onInfoAlertSubmit = () => {
    onDrawerFormSubmit(formData, 'alertModal')
    setAlertModalVisible(false)
  }

  const onDrawerClose = () => {
    setFormData({})
    onClose()
  }
  return (
    <>
      <Drawer
        isOpen={isOpen}
        size={'md'}
        placement="right"
        onClose={onDrawerClose}
      >
        <DrawerOverlay />
        <form onSubmit={handleSubmit}>
          <DrawerContent bg={'#f1f1f1'}>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              {isCreate ? 'Create ' : 'Edit '} {openedFrom}
            </DrawerHeader>

            <DrawerBody>
              <Stack spacing="24px">
                {inputElements.map((element, index) => (
                  <FormElements
                    key={index}
                    type={element.type}
                    onFormChange={onFormChange}
                    value={formData[element.name]}
                    name={element.name}
                    label={element.label}
                    required={element.required}
                    selectOptions={
                      element?.selectOptions?.length
                        ? element.selectOptions
                        : selectOptions
                    }
                    checkboxOptions={checkboxOptions}
                    radioOptions={element.radioOptions}
                    helperText={element.helperText}
                  />
                ))}
              </Stack>
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px">
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" type={'submit'} isLoading={loading}>
                Submit
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
      <InfoAlert
        isOpen={alertModalVisible}
        onClose={() => {
          setAlertModalVisible(false)
          onClose()
        }}
        onSubmit={onInfoAlertSubmit}
        headerText={`Save Tree?`}
        bodyText={
          'You need to save the tree before creating/updating a channel'
        }
        buttonText={'Save and Create/Update'}
        colorScheme={'blue'}
      />
    </>
  )
}

export default FormDrawer
