import {
  Center,
  Flex,
  Hide,
  Select,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PfNormRadio from '../../elements/pfNormRadio'
import SortIcon from '../../elements/sortIcon'
import { Address } from '../../graphql/generated/graphql'
import { useAuth } from '../../hooks/use-auth'
import { tableBreak } from '../../styles/buildingsVariables'
import PfTableRow from './pfTableRow'
import useMultiSort from './pfTableSort'

export type BuildingProps = {
  defaultName: string
  name: string
  slug: string
  type: string
  floorArea: number
  floorAreaDisplay: number
  epcRating: string
  epcUrl: string
  thumbnail: string
  picture: string
  projectRef: string
  company: string
  id: string
  address: Address
  lastSeen?: Date
  buildingTransmitting: boolean
  channelIssues: {
    channelId: string
    lastSeen: Date | null
    isImportant: boolean
    notTransmitting: boolean
    includesZeroes: boolean
    notCumulative: boolean
  }[]
  tlData: PortfolioItem[]
}

export type PortfolioItem = {
  type: string
  value: number
  target: number
  units: string
}

const loadingBuilding = {
  name: 'Building the First',
  slug: 'building-the-first',
  type: 'distrib',
  floorArea: 24302,
  floorAreaDisplay: 24302,
  epcRating: 'A+',
  epcUrl: 'https://www.gov.uk/find-energy-certificate',
  thumbnail: 'https://avatars.dicebear.com/api/bottts/building.svg',
  tlData: [
    { type: 'energy', value: 456100000, target: 300000000, units: 'Wh' },
    { type: 'carbon', value: 78600, target: 78600, units: 'kg' },
    { type: 'renewables', value: 1993, target: 4000, units: 'm' },
  ],
}

type PfTableProps = {
  loading: boolean
  buildings: BuildingProps[]
  dataPeriod: string
}

const PfTable = ({ loading, buildings, dataPeriod }: PfTableProps) => {
  const { t, i18n } = useTranslation('portfolio')
  const auth = useAuth()
  const [normalisation, setNormalisation] = useState<string>('total')
  const [preferredUnits, setPreferredUnits] = useState<string>(
    localStorage.getItem(`${auth.user.id}-preferred-units`) ?? 'sqm',
  )
  const { state, dispatch, sortFunction } = useMultiSort()

  // note, this copies the array, which is useful, because the sort
  // is in place
  const tableBuildings: BuildingProps[] = loading
    ? Array(7)
        .fill(loadingBuilding)
        .map((placeholder, idx) => ({
          ...placeholder,
          slug: `${placeholder.slug}-idx${idx}`,
        }))
    : // boo
      buildings
        .map((building) => {
          return {
            ...building,
            floorAreaDisplay:
              preferredUnits === 'sqft'
                ? Math.round(building.floorArea * 10.7639)
                : building.floorArea,
            type: t(`buildingType.${building.type}`),
            tlData: building.tlData.map((datum) => {
              const normDenom =
                normalisation === 'total'
                  ? 1
                  : preferredUnits === 'sqm'
                    ? building.floorArea
                    : Math.round(building.floorArea * 10.7639)

              return {
                type: datum.type,
                value: datum.value / normDenom,
                target: datum.target / normDenom,
                units: datum.units,
              }
            }),
          }
        })
        .sort(sortFunction)
  //tableBuildings.sort(sortFunction)

  // TODO: Table columns should collapse on smaller screens to make the
  // table display nicely, leave this here for now as it is helpful for
  // that (need to omit columns rather than use responsive visible params)
  //const breakpoint = useBreakpointValue({
  //  base: 'base',
  //  sm: 'sm',
  //  md: 'md',
  //  lg: 'lg',
  //  xl: 'xl',
  //  '2xl': '2xl',
  //})

  const locale = i18n.resolvedLanguage

  const displayDataPeriod = (dataPeriod: string) => {
    switch (dataPeriod) {
      case 'daily':
        return 'Day'
      case 'weekly':
        return 'Week'
      case 'yearly':
        return 'Year'
      default:
        return 'Month'
    }
  }

  const showIssues = buildings.some(
    (building) => !building.buildingTransmitting,
  )

  return (
    <TableContainer>
      <Table size={['sm', 'sm', 'sm', 'sm', 'md']}>
        <Thead backgroundColor={'#f9f9f9'}>
          <Tr>
            {/* Name */}
            <Th width={'30%'} rowSpan={2}>
              <Flex>
                {t('siteName').toLocaleUpperCase(locale)}

                <SortIcon
                  sort={state.siteName}
                  setSort={(sort, sortKey) => {
                    dispatch({ type: sort, payload: sortKey })
                  }}
                  sortKey={'siteName'}
                />
              </Flex>
            </Th>

            {/* {showIssues && (
              <Hide breakpoint={tableBreak.tiny}>
                <Th rowSpan={2}>
                  <Text>{t('issues').toLocaleUpperCase(locale)}</Text>
                </Th>
              </Hide>
            )} */}

            {/* Details */}
            <Hide breakpoint={tableBreak.large}>
              <Th rowSpan={2}>
                <Center>
                  {t(`floorArea`).toLocaleUpperCase(locale)}{' '}
                  <Select
                    size={'sm'}
                    w={'70px'}
                    ml={2}
                    variant="outline"
                    defaultValue={preferredUnits}
                    fontWeight={'bold'}
                    onChange={(e) => {
                      localStorage.setItem(
                        `${auth.user.id}-preferred-units`,
                        e.target.value,
                      )
                      setPreferredUnits(e.target.value)
                    }}
                  >
                    <option value="sqm" style={{ fontWeight: 'bold' }}>
                      m<sup>2</sup>
                    </option>
                    <option value="sqft" style={{ fontWeight: 'bold' }}>
                      sqft
                    </option>
                  </Select>
                  <SortIcon
                    sort={state.floorAreaDisplay}
                    setSort={(sort, sortKey) => {
                      dispatch({ type: sort, payload: sortKey })
                    }}
                    sortKey={'floorArea'}
                  />
                </Center>
              </Th>
            </Hide>
            <Hide breakpoint={tableBreak.huge}>
              <Th rowSpan={2}>{t('epcRating').toLocaleUpperCase(locale)}</Th>
            </Hide>

            {/* Spacer */}
            {/* <Show breakpoint="(max-width: 1780px)">
              <Th
                width={{ sm: '15%', md: '15%', lg: '15%' }}
                whiteSpace={'pre-wrap'}
              >
              </Th>
            </Show> */}

            {/* Target upper part */}
            <Hide breakpoint={tableBreak.small}>
              <Th colSpan={3}>
                <Spacer />
                <Flex alignItems={'center'}>
                  {t('total').toLocaleUpperCase(locale)} / &nbsp;
                  {displayDataPeriod(dataPeriod).toLocaleUpperCase()}
                  <Spacer />
                  <PfNormRadio
                    value={normalisation}
                    onChange={setNormalisation}
                    preferredUnits={preferredUnits}
                  />
                </Flex>
              </Th>
            </Hide>
          </Tr>

          <Tr>
            {/* Targets lower part */}
            <Hide breakpoint={tableBreak.tiny}>
              <Th colSpan={1}>
                <Flex justifyContent={'center'}>
                  {t('energyUsed').toLocaleUpperCase()}
                  <SortIcon
                    sort={state.energy}
                    setSort={(sort, sortKey) => {
                      dispatch({ type: sort, payload: sortKey })
                    }}
                    sortKey={'energy'}
                  />
                </Flex>
              </Th>
            </Hide>

            <Hide breakpoint={tableBreak.small}>
              <Th colSpan={1}>
                <Flex>
                  {t('renewableGeneration').toLocaleUpperCase()}
                  <Spacer />
                  <SortIcon
                    sort={state.renewables}
                    setSort={(sort, sortKey) => {
                      dispatch({ type: sort, payload: sortKey })
                    }}
                    sortKey={'renewables'}
                  />
                </Flex>
              </Th>
            </Hide>
            <Hide breakpoint={tableBreak.medium}>
              <Th colSpan={1}>
                <Flex>
                  {t('carbonEmissions').toLocaleUpperCase()}
                  <Spacer />
                  <SortIcon
                    sort={state.carbon}
                    setSort={(sort, sortKey) => {
                      dispatch({ type: sort, payload: sortKey })
                    }}
                    sortKey={'carbon'}
                  />
                </Flex>
              </Th>
            </Hide>
          </Tr>
        </Thead>
        <Tbody>
          {tableBuildings.map((building) => (
            <PfTableRow
              locale={locale ?? 'en'}
              {...building}
              key={building.slug}
              loading={loading}
              floorAreaDisplay={building.floorAreaDisplay}
              showIssues={showIssues}
              channelIssues={building.channelIssues ?? []}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default PfTable
