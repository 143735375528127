import { Search2Icon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Hide,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  ChannelSearchManyQuery,
  useCheckChannelDataMutation,
} from '../../graphql/generated/graphql'
import { useAuth } from '../../hooks/use-auth'
import {
  InspectorChannel,
  inspectorChannelGuard,
} from '../../Pages/buildings/issues'
import { tableBreak } from '../../styles/dataVariables'
import { isCurrentUserStaff } from '../../utils/helpers/basicFunc'
import { getCategory } from '../../utils/unitMaps/unitCategories'
import { isIncomingUnit } from '../../utils/unitMaps/unitEnums'
import { issueIcon } from '../icon/issueIcon'

export const ChannelListTable = ({
  onChannelSelect,
  channels,
  selectedChannels,
  buildingSlug,
}: {
  onChannelSelect: (arg1: string) => void
  channels: ChannelSearchManyQuery['channelSearchMany']
  selectedChannels: string[]
  buildingSlug: string
}) => {
  const Auth = useAuth(),
    { user } = Auth,
    { isStaff } = user,
    havePermissions = isStaff ?? isCurrentUserStaff()
  const toast = useToast()

  const [searchValue, setSearchValue] = useState('')
  const [filteredChannels, setFilteredChannels] =
    useState<ChannelSearchManyQuery['channelSearchMany']>(channels)

  const [checkChannel] = useCheckChannelDataMutation()

  useEffect(() => {
    setFilteredChannels(channels)
  }, [channels])

  useEffect(() => {
    if (searchValue) {
      setFilteredChannels(
        channels.filter((channel) =>
          channel?.name?.toLowerCase().includes(searchValue),
        ),
      )
    } else {
      setFilteredChannels(channels)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const createIssueIcon = (
    channelIssues: InspectorChannel,
    measurementType?: string,
  ) => {
    if (measurementType === 'unknown') {
      return issueIcon(
        buildingSlug,
        'spanner',
        'Channel has not yet been configured',
      )
    }
    if (!channelIssues) {
      return <></>
    }
    if (!channelIssues.lastSeen) {
      return issueIcon(
        buildingSlug,
        'spanner',
        'Channel has not yet transmitted data',
      )
    }

    if (channelIssues.notTransmitting) {
      return issueIcon(buildingSlug, 'error', 'Channel not transmitting')
    }

    if (channelIssues.notCumulative && channelIssues.includesZeroes) {
      return issueIcon(
        buildingSlug,
        'warn',
        'Channel has multiple issues associated with it',
      )
    }

    if (channelIssues.notCumulative) {
      return issueIcon(buildingSlug, 'warn', 'Channel has non-cumulative data')
    }

    if (channelIssues.includesZeroes) {
      return issueIcon(buildingSlug, 'warn', 'Channel raw data includes zeroes')
    }

    return <></>
  }

  const refreshChannel = async (channelId?: string, name?: string) => {
    if (!channelId) {
      return
    }
    checkChannel({
      variables: {
        data: {
          id: channelId,
        },
      },
      onCompleted: () => {
        toast({
          title: name ? `Check in progress for ${name}` : `Check in progress`,
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }
  const typeOrder = ['actual', 'virtual', 'shadow', null, undefined]
  const sortedChannels = structuredClone(filteredChannels)
    .sort((a, b) => b?.name?.localeCompare(a?.name ?? '') ?? 1)
    .sort(
      (a, b) =>
        typeOrder.indexOf(b?.channelType) - typeOrder.indexOf(a?.channelType),
    )
    .sort((a, b) =>
      selectedChannels.some((channel) => channel === a?.id) ? -1 : 1,
    )

  return (
    <Box mt={4}>
      <Center justifyContent={'space-between'} mb={4}>
        <Heading fontSize={{ lg: 36, md: 28, base: 20 }} flexGrow={1}>
          All Channels
        </Heading>
        <InputGroup flexBasis={{ base: '50%', md: '35%', xl: '25%' }}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search channels... "
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </InputGroup>
      </Center>
      <TableContainer bg={'white'} maxH={350} overflowY={'auto'}>
        {sortedChannels.length ? (
          <Table variant="simple">
            <TableCaption>Upto five channels can be selected</TableCaption>
            <Thead>
              <Tr>
                <Th pl={0}>Channel Name</Th>
                <Hide breakpoint={tableBreak.large}>
                  <Th pl={0}>Channel Type</Th>
                </Hide>
                <Hide breakpoint={tableBreak.medium}>
                  <Th pl={0}>Meter Type</Th>
                </Hide>
                <Th pl={0}>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {sortedChannels.map((channel, i) => {
                if (!channel) {
                  return <></>
                }
                return (
                  <Tr key={i}>
                    <Td pl={0}>{channel?.name}</Td>
                    <Hide breakpoint={tableBreak.large}>
                      <Td pl={0} textTransform={'capitalize'}>
                        {channel?.channelType &&
                        channel.channelType === 'shadow'
                          ? 'composite'
                          : channel.channelType}
                      </Td>
                    </Hide>
                    <Hide breakpoint={tableBreak.medium}>
                      <Td pl={0} textTransform={'capitalize'}>
                        {channel?.incomingUnit &&
                        isIncomingUnit(channel?.incomingUnit)
                          ? getCategory(channel.incomingUnit)
                          : undefined}
                      </Td>
                    </Hide>
                    <Td pl={0}>
                      <Flex
                        maxWidth={'210px'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        gap={2}
                      >
                        <Flex gap={2}>
                          <Button
                            colorScheme={'blue'}
                            onClick={() => onChannelSelect(channel?.id ?? '')}
                            variant={
                              selectedChannels.includes(channel?.id ?? '')
                                ? 'outline'
                                : 'solid'
                            }
                          >
                            {selectedChannels.includes(channel?.id ?? '')
                              ? 'Hide'
                              : 'View'}
                          </Button>
                          {havePermissions && (
                            <Hide breakpoint={tableBreak.small}>
                              <Button
                                // leftIcon={<RefreshCcw />}
                                colorScheme={'blue'}
                                onClick={() =>
                                  refreshChannel(
                                    channel?.id ?? undefined,
                                    channel?.name ?? undefined,
                                  )
                                }
                                variant={'solid'}
                              >
                                Check Data
                              </Button>
                            </Hide>
                          )}
                        </Flex>
                        {inspectorChannelGuard(channel.channelIssues) &&
                          createIssueIcon(
                            channel.channelIssues,
                            channel?.measurementType ?? undefined,
                          )}
                      </Flex>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th pl={0}>Channel Name</Th>
                <Hide breakpoint={tableBreak.large}>
                  <Th pl={0}>Channel Type</Th>
                </Hide>
                <Hide breakpoint={tableBreak.medium}>
                  <Th pl={0}>Meter Type</Th>
                </Hide>
                <Th pl={0}>Action</Th>
              </Tr>
            </Tfoot>
          </Table>
        ) : (
          <Center w={'100%'} minH={200}>
            <Heading>No Data</Heading>
          </Center>
        )}
      </TableContainer>
    </Box>
  )
}

export default ChannelListTable
