import { DocumentNode, useMutation } from '@apollo/client'
import {
  Button,
  Center,
  Heading,
  Image,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpoint,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { User } from 'react-feather'
import {
  GET_CHANNEL_SEARCH_MANY,
  GET_COMBI_CHANNELS,
  GET_COMPANIES,
  GET_RELATIONSHIP_TREE,
  GET_USERS,
} from '../../graphql/API/queries'
import {
  camelCaseToNormal,
  isCurrentUserEndUser,
  isCurrentUserStaff,
} from '../../utils/helpers/basicFunc'
import {
  mapKeyValue,
  PossibleDataTypes,
} from '../../utils/pageDrawerInputLists/inputLists'
import { tableHeaders } from '../../utils/tableDataConstruct/tableDataConstruct'
import FormDrawer, { FormDataType } from '../formDrawer/formDrawer'
import InfoAlert from '../infoAlert/infoAlert'
import {
  DELETE_CHANNEL,
  DELETE_COMPANY,
  DELETE_USER,
  EDIT_CHANNELS,
  EDIT_COMPANIES,
  EDIT_USERS,
} from '../../graphql/API/mutations'
import { SelectOptionProps } from '../../elements/formElements/formElements'
import {
  Channel,
  ChannelSearchManyQuery,
  CompaniesQuery,
  TargetAlert,
  UserGetManyQuery,
} from '../../graphql/generated/graphql'
import TargetAlerts from '../../Pages/targetAlerts/targetAlerts'

export type ListTableProps = {
  constructedData:
    | ChannelSearchManyQuery['channelSearchMany']
    | UserGetManyQuery['userGetMany']
    | CompaniesQuery['companies']
    | TargetAlert[]
    | undefined
    | null
  page: string
  formDrawerProps?: {
    selectOptions?: SelectOptionProps[]
    checkboxOptions?: SelectOptionProps[]
  }
}

export type PossibleDataTypesWithUndefined =
  | (PossibleDataTypes & { [key: string]: undefined | null })
  | undefined
  | null

const tableDataAPI: { [key: string]: DocumentNode } = {
  usersEdit: GET_USERS,
  companiesEdit: GET_COMPANIES,
  channels: GET_CHANNEL_SEARCH_MANY,
}

const editMutationAPI: { [key: string]: DocumentNode } = {
  usersEdit: EDIT_USERS,
  companiesEdit: EDIT_COMPANIES,
  channels: EDIT_CHANNELS,
}

const deleteMutationAPI: { [key: string]: DocumentNode } = {
  usersEdit: DELETE_USER,
  companiesEdit: DELETE_COMPANY,
  channels: DELETE_CHANNEL,
}

const ListTable = ({
  constructedData,
  page,
  formDrawerProps,
}: ListTableProps) => {
  const [isOpen, setDrawerVisible] = useState(false)
  const [clickedElement, setClickedElement] = useState<PossibleDataTypes>()
  const [isDeleteOpen, setModalVisible] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
  const [isTargetAlertsOpen, setTargetAlertModal] = useState(false)
  const breakPoint = useBreakpoint()

  const havePermissions =
    page === 'channels' ? !isCurrentUserEndUser() : isCurrentUserStaff()
  const channelVariables = {
    where: {
      buildingId: (
        constructedData as ChannelSearchManyQuery['channelSearchMany']
      )?.[0]?.buildingId,
    },
  }
  let refetchQueries = [
    {
      query: tableDataAPI[page],
      variables: {
        where: {},
      },
    },
  ]
  if (page === 'channels') {
    refetchQueries = [
      {
        query: tableDataAPI[page],
        variables: channelVariables,
      },
      {
        query: GET_RELATIONSHIP_TREE,
        variables: channelVariables,
      },
      {
        query: GET_COMBI_CHANNELS,
        variables: channelVariables,
      },
    ]
  }
  const [editRow] = useMutation(editMutationAPI[page], {
    onCompleted: () => {
      setDrawerVisible(false)
      setButtonLoader(false)
    },
    refetchQueries,
    onError: (err) => {
      setButtonLoader(false)
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })
  const [deleteRow] = useMutation(deleteMutationAPI[page], {
    refetchQueries,
    onCompleted: () => {
      setButtonLoader(false)
      setModalVisible(false)
    },
    onError: (error) => {
      setButtonLoader(false)
      setModalVisible(false)
      toast({
        title: error.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })
  const toast = useToast()

  const singularHeading: { [key: string]: string } = {
    usersEdit: 'User',
    companiesEdit: 'Company',
    channels: 'Channel',
  }
  const onClose = () => {
    setDrawerVisible(false)
  }

  const onDrawerFormSubmit = (formData: FormDataType) => {
    setButtonLoader(true)
    let editedData: PossibleDataTypes = { id: clickedElement?.id!, ...formData }
    if (page === 'channels') {
      editedData = {
        channelId: clickedElement?.id!,
        period: 'none',
        ...formData,
      }
    }
    editRow({
      variables: { data: editedData },
    })
  }

  const selectRequiredParams = () => {
    return {
      page,
      data: clickedElement!,
    }
  }

  const onButtonClick = (clickedParams: {
    role: string
    element: PossibleDataTypes
  }) => {
    setClickedElement(clickedParams?.element)
    if (clickedParams.role === 'edit') {
      setDrawerVisible(true)
    } else if (clickedParams.role === 'delete') {
      setModalVisible(true)
    } else if (clickedParams.role === 'channelAlert') {
      setTargetAlertModal(true)
    }
  }

  const onDeleteModalClose = () => {
    setModalVisible(false)
  }

  const onDelete = () => {
    setButtonLoader(true)
    const data =
      page === 'channels'
        ? { channelId: clickedElement?.id }
        : { id: clickedElement?.id }
    deleteRow({
      variables: { data },
    })
  }

  const tableElementsSelect = () => {
    switch (breakPoint) {
      case 'base': {
        return `${page}-sm`
      }
      case 'md': {
        return `${page}-md`
      }
      default: {
        return page
      }
    }
  }

  const getTableData = (
    key: string,
    object: PossibleDataTypesWithUndefined,
  ) => {
    // Not able to assign type as object has multiple types
    let td = (
      <Td
        key={key}
        width={'15%'}
        whiteSpace={'pre-wrap'}
        textTransform={'capitalize'}
      >
        {object?.[key]}
      </Td>
    )
    if (key === 'picture' || key === 'portfolioImage' || key === 'logo') {
      td = (
        <Td key={key} width={'15%'} whiteSpace={'pre-wrap'}>
          {!object?.[key] ? (
            <User />
          ) : (
            <Image
              src={object[key] as string}
              alt={`${page}.png`}
              boxSize="75px"
              maxW={'unset'}
              objectFit={'contain'}
            />
          )}
        </Td>
      )
    } else if (page === 'usersEdit' && key === 'company') {
      td = (
        <Td key={key} width={'15%'} whiteSpace={'pre-wrap'}>
          {
            formDrawerProps?.selectOptions?.find(
              (option: SelectOptionProps) => option?.value === object?.[key],
            )?.text
          }
        </Td>
      )
    } else if (page === 'channels') {
      if (key === 'name') {
        td = (
          <Td
            key={key}
            width={'15%'}
            whiteSpace={'pre-wrap'}
            textTransform={'capitalize'}
          >
            <Link href={`details?channelId=${object?.['id']}`}>
              {object?.[key]}
            </Link>
          </Td>
        )
      } else if (
        object?.channelType === 'virtual' &&
        key === 'measurementType'
      ) {
        td = <Td key={key} width={'15%'} whiteSpace={'pre-wrap'} />
      }
    }
    return td
  }

  const closeAlerts = () => {
    setTargetAlertModal(false)
  }

  return constructedData?.length ? (
    <TableContainer>
      <Table variant="simple" w={'100%'}>
        <Thead>
          <Tr>
            {tableHeaders[tableElementsSelect()].map((datum: string) => (
              <Th key={datum}>
                {datum === 'picture' ? '' : camelCaseToNormal(datum)}
              </Th>
            ))}
            <Th pt={'1.5%'} pb={'1.5%'} rowSpan={2}>
              Action
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {constructedData.map((values, i: number) => (
            <Tr key={i}>
              {tableHeaders[tableElementsSelect()].map((datum) =>
                getTableData(datum, values as PossibleDataTypesWithUndefined),
              )}
              <Td w={'15%'} whiteSpace={'nowrap'}>
                {page === 'channels' && !isCurrentUserEndUser() && (
                  <Button
                    variant={'solid'}
                    colorScheme={'gray'}
                    mr={4}
                    onClick={() =>
                      onButtonClick({
                        role: 'channelAlert',
                        element: values as PossibleDataTypes,
                      })
                    }
                  >
                    View Alerts
                  </Button>
                )}
                {havePermissions && (
                  <>
                    <Button
                      variant={'outline'}
                      colorScheme={'purple.500'}
                      onClick={() =>
                        onButtonClick({
                          role: 'edit',
                          element: values as PossibleDataTypes,
                        })
                      }
                    >
                      Edit
                    </Button>
                    {(page !== 'channels' ||
                      (page === 'channels' &&
                        (values as Partial<Channel>).channelType ===
                          'virtual')) && (
                      <Button
                        colorScheme={'red'}
                        ml={4}
                        onClick={() =>
                          onButtonClick({
                            role: 'delete',
                            element: values as PossibleDataTypes,
                          })
                        }
                      >
                        Delete
                      </Button>
                    )}
                  </>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <FormDrawer
        openedFrom={singularHeading[page]}
        isCreate={false}
        isOpen={isOpen}
        onClose={onClose}
        loading={buttonLoader}
        inputElements={mapKeyValue(selectRequiredParams())}
        onDrawerFormSubmit={onDrawerFormSubmit}
        selectOptions={formDrawerProps?.selectOptions}
        checkboxOptions={formDrawerProps?.checkboxOptions}
      />
      <InfoAlert
        isOpen={isDeleteOpen}
        onClose={onDeleteModalClose}
        onSubmit={onDelete}
        headerText={
          page === 'usersEdit'
            ? `Delete ${clickedElement?.firstName}?`
            : `Delete ${clickedElement?.name}`
        }
        bodyText={"Are you sure? You can't undo this action afterwards."}
        buttonText={'Delete'}
        loading={buttonLoader}
      />
      {isTargetAlertsOpen && (
        <TargetAlerts
          isOpen={isTargetAlertsOpen}
          onClose={closeAlerts}
          channelId={clickedElement?.id as string}
          channelName={clickedElement?.name as string}
        />
      )}
    </TableContainer>
  ) : (
    <Center>
      <Heading>No Data</Heading>
    </Center>
  )
}

export default ListTable
