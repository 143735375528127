import { DrawerInputsProps } from '../../components/formDrawer/formDrawer'
import DashboardTypes from '../../elements/dashboardTypes/dashboardTypes'
import { countryCodes } from '../locationMaps/countryCodes'
import { gasRegions } from '../locationMaps/gasRegions'

export type PossibleDataTypes = {
  [key: string]:
    | string
    | number
    | (string | number)[]
    | boolean
    | PossibleDataTypes[]
}

// As we are using common drawer for every edit and create, we can update the fields to show here.

const createCompanyInputs = [
  {
    type: 'text',
    required: true,
    name: 'name',
    label: 'Company Name',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'portfolioImage',
    label: 'Image URL',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'logo',
    label: 'Logo',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'slug',
    label: 'Slug',
    value: '',
  },
  {
    type: 'patternText',
    required: false,
    name: 'primaryColor',
    label: 'Primary Color',
    value: '#d7dddb',
  },
  {
    type: 'patternText',
    required: false,
    name: 'secondaryColor',
    label: 'Secondary Color',
    value: '#7BA408',
  },
  {
    type: 'patternText',
    required: false,
    name: 'fontColor',
    label: 'Font Color',
    value: '#417e9a',
  },
]

const createCombiChannel = [
  {
    type: 'text',
    required: true,
    name: 'channelName',
    label: 'Channel name',
    value: undefined,
  },
  // {
  //   type: 'select',
  //   required: true,
  //   name: 'period',
  //   label: 'Period',
  //   value: '-',
  //   selectOptions: [
  //     { text: 'Hourly', value: 'hourly' },
  //     { text: 'Daily', value: 'daily' },
  //     { text: 'Weekly', value: 'weekly' },
  //     { text: 'Monthly', value: 'monthly' },
  //     { text: 'Yearly', value: 'yearly' },
  //   ],
  // },
]

const buildingInputs = [
  {
    type: 'number',
    required: true,
    name: 'floorArea',
    label: 'Floor Area',
    value: undefined,
  },
  {
    type: 'text',
    required: true,
    name: 'epcRating',
    label: 'EPC Rating',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'epcUrl',
    label: 'EPC URL',
    value: undefined,
  },
  {
    type: 'text',
    required: true,
    name: 'slug',
    label: 'Slug',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'picture',
    label: 'Picture',
    value: '',
  },
  {
    type: 'year',
    required: false,
    name: 'constructionYear',
    label: 'Year of Construction',
    value: undefined,
  },
  {
    type: 'number',
    required: false,
    name: 'breeamScore',
    label: 'BREEAM Score',
    value: undefined,
  },
  // {
  //   type: 'text',
  //   required: true,
  //   name: 'thumb',
  //   label: 'Thumb',
  //   value: undefined,
  // },
  {
    type: 'text',
    required: true,
    name: 'projectRef',
    label: 'Project Ref',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'firstLine',
    label: 'Address Line 1',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'secondLine',
    label: 'Address Line 2',
    value: undefined,
  },

  {
    type: 'text',
    required: false,
    name: 'city',
    label: 'City',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'region',
    label: 'Region',
    value: undefined,
  },

  {
    type: 'text',
    required: false,
    name: 'postcode',
    label: 'Postcode',
    value: undefined,
  },
  {
    type: 'select',
    required: true,
    name: 'country',
    label: 'Country',
    value: undefined,
    selectOptions: [
      ...countryCodes.map((country) => ({
        text: country.name,
        value: country.code,
      })),
    ],
  },
  {
    type: 'select',
    required: true,
    name: 'gasRegion',
    label: 'Gas Region',
    value: undefined,
    selectOptions: [
      ...gasRegions.map((region) => ({
        text: region.name,
        value: region.code,
      })),
    ],
  },
]

const companyRelationInputs = [
  {
    type: 'select',
    required: true,
    name: 'relationshipType',
    label: 'Company Relationship Type',
    value: '-',
    selectOptions: [
      { text: 'Owner', value: 'owner' },
      { text: 'Occupier', value: 'occupier' },
      { text: 'Reseller', value: 'reseller' },
      { text: 'Viewer', value: 'viewer' },
    ],
  },
  {
    type: 'text',
    required: false,
    name: 'buildingName',
    label: 'Building Name',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'companyReferenceName',
    label: 'Company Reference Name',
    value: undefined,
  },
  {
    type: 'text',
    required: false,
    name: 'companyReference',
    label: 'Company Reference',
    value: undefined,
  },
]

export const allDrawerInputs: { [key: string]: DrawerInputsProps[] } = {
  buildingsCreate: [
    {
      type: 'text',
      required: true,
      name: 'defaultName',
      label: 'Default Name',
      value: undefined,
    },
    ...buildingInputs,
    {
      type: 'select',
      required: true,
      name: 'companyId',
      label: 'Company',
      value: '-',
      selectOptions: [],
    },
    {
      type: 'select',
      required: true,
      name: 'companyRelationshipType',
      label: 'Company Relationship Type',
      value: '-',
      selectOptions: [
        { text: 'Owner', value: 'owner' },
        { text: 'Occupier', value: 'occupier' },
        { text: 'Reseller', value: 'reseller' },
        { text: 'Viewer', value: 'viewer' },
      ],
    },
    {
      type: 'text',
      required: false,
      name: 'name',
      label: "Company's Building Name",
      value: undefined,
    },
    {
      type: 'imageRadio',
      required: true,
      name: 'dashboardStyle',
      label: 'Dashboard Type',
      value: 'full',
      radioOptions: [
        {
          text: 'Full',
          value: 'full',
          component: <DashboardTypes type="full" />,
        },
        {
          text: 'Simple',
          value: 'simple',
          component: <DashboardTypes type="simple" />,
        },
      ],
    },
  ],
  buildingsEdit: [
    {
      type: 'text',
      required: true,
      name: 'defaultName',
      label: 'Default Name',
      value: undefined,
    },
    {
      type: 'text',
      required: false,
      name: 'name',
      label: 'Name',
      value: undefined,
    },
    ...buildingInputs,
  ],
  dashboardEdit: [
    {
      type: 'select',
      required: true,
      name: 'period',
      label: 'Period',
      value: 'monthly',
      selectOptions: [
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Monthly', value: 'monthly' },
        { text: 'Yearly', value: 'yearly' },
      ],
    },
    {
      type: 'select',
      required: true,
      name: 'dashboardStyle',
      label: 'Dashboard Style',
      value: 'full',
      selectOptions: [
        // { text: 'Simple', value: 'simple' },
        { text: 'Full', value: 'full' },
        { text: 'Classic', value: 'classic' },
        { text: 'Graph', value: 'graph' },
      ],
    },
    {
      type: 'text',
      required: false,
      name: 'infoMessage',
      label: 'How are we doing text',
      helperText: 'Ideally this would consist of 30 characters',
      value: undefined,
    },
    {
      type: 'text',
      required: false,
      name: 'tryMessage',
      label: 'Why not text',
      helperText: 'Ideally this would consist of 30 characters',
      value: undefined,
    },
  ],
  users: [
    {
      type: 'text',
      required: true,
      name: 'firstName',
      label: 'First Name',
      value: undefined,
    },
    {
      type: 'text',
      required: true,
      name: 'lastName',
      label: 'Last Name',
      value: undefined,
    },
    {
      type: 'email',
      required: true,
      name: 'email',
      label: 'Email',
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      name: 'role',
      label: 'Role',
      value: '-',
      selectOptions: [
        { text: 'Staff', value: 'staff' },
        { text: 'Client', value: 'reseller' },
        { text: 'User', value: 'endUser' },
      ],
    },
    {
      type: 'select',
      required: true,
      name: 'companyId',
      label: 'Company',
      value: '-',
      selectOptions: [],
    },
  ],
  usersEdit: [
    {
      type: 'text',
      required: true,
      name: 'firstName',
      label: 'First Name',
      value: undefined,
    },
    {
      type: 'text',
      required: false,
      name: 'lastName',
      label: 'Last Name',
      value: undefined,
    },
    {
      type: 'text',
      required: false,
      name: 'slug',
      label: 'Slug',
      value: undefined,
    },
    {
      type: 'text',
      required: false,
      name: 'picture',
      label: 'Picture',
      value: undefined,
    },
  ],
  companies: createCompanyInputs,
  companiesEdit: createCompanyInputs,
  channels: [
    {
      type: 'text',
      required: true,
      name: 'name',
      label: 'Channel Name',
      value: undefined,
    },
    // {
    //   type: 'select',
    //   required: true,
    //   name: 'period',
    //   label: 'Period',
    //   value: '-',
    //   selectOptions: [
    //     { text: 'Hourly', value: 'hourly' },
    //     { text: 'Daily', value: 'daily' },
    //     { text: 'Weekly', value: 'weekly' },
    //     { text: 'Monthly', value: 'monthly' },
    //     { text: 'Yearly', value: 'yearly' },
    //   ],
    // },
    {
      type: 'text',
      required: false,
      name: 'reference',
      label: 'Reference',
      value: undefined,
    },
  ],
  combiChannels: createCombiChannel,
  combiChannelsEdit: [
    ...createCombiChannel,
    {
      type: 'checkboxgroup',
      required: false,
      name: 'channelList',
      label: 'Channel Lists',
      value: [],
    },
  ],
  targets: [
    {
      type: 'text',
      required: true,
      name: 'name',
      label: 'Target name',
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      name: 'period',
      label: 'Period',
      value: '-',
      selectOptions: [
        { text: 'Hourly', value: 'hourly' },
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Monthly', value: 'monthly' },
        { text: 'Yearly', value: 'yearly' },
      ],
    },
    {
      type: 'select',
      required: true,
      name: 'triggerCondition',
      label: 'Condition',
      value: 'always',
      selectOptions: [
        { text: 'None', value: 'always' },
        { text: 'Above', value: 'aboveTarget' },
        { text: 'Below', value: 'belowTarget' },
      ],
    },
    {
      type: 'number',
      required: false,
      name: 'targetValue',
      label: 'Target Value',
      value: undefined,
    },
    {
      type: 'select',
      required: true,
      name: 'severity',
      label: 'Alert Severity',
      value: '-',
      selectOptions: [
        { text: 'Silenced', value: 'silenced' },
        { text: 'Digest', value: 'digest' },
        { text: 'General', value: 'general' },
        { text: 'Immediate', value: 'immediate' },
        { text: 'Critical', value: 'critical' },
      ],
    },
  ],
  companyRelationCreate: [
    {
      type: 'select',
      required: true,
      name: 'companyId',
      label: 'Company',
      value: '-',
      selectOptions: [],
    },
    ...companyRelationInputs,
  ],
  companyRelationEdit: companyRelationInputs,
}

// Below function is to populate the edit values in the modal.
export function mapKeyValue(requiredParams: {
  page: string
  data: {
    [key: string]:
      | string
      | number
      | (string | number)[]
      | boolean
      | PossibleDataTypes
      | PossibleDataTypes[]
  }
}) {
  let currentInputs = allDrawerInputs[requiredParams.page]
  currentInputs = currentInputs.map((datum) => {
    return {
      ...datum,
      value: requiredParams?.data?.[datum.name] ?? '',
    }
  })
  return currentInputs
}

// currentInputs is this
//
// dashboardEdit: [
//   {
//     type: 'select',
//     required: false,
//     name: 'dashboardPeriod',
//     label: 'Dashboard Period',
//     value: 'monthly',
//     selectOptions: [
//       { text: 'Daily', value: 'daily' },
//       { text: 'Weekly', value: 'weekly' },
//       { text: 'Monthly', value: 'monthly' },
//       { text: 'Yearly', value: 'yearly' },
//     ],
//   },
// ],
