export const incomingUnitMap = [
  {
    name: 'Cubic Metres (Gas)',
    code: 'gasMetersCubed',
  },
  {
    name: 'Cubic Feet (Gas)',
    code: 'gasFeetCubed',
  },
  {
    name: 'kWh (Electricity)',
    code: 'electricityKwh',
  },
  {
    name: 'BTUs (Electricity)',
    code: 'electricityBtu',
  },
  {
    name: 'KiloJoules (Electricity)',
    code: 'electricityKj',
  },
  {
    name: 'Cubic Metres (Water)',
    code: 'waterMetersCubed',
  },
  {
    name: 'Cubic Feet (Water)',
    code: 'waterFeetCubed',
  },
  {
    name: 'Imperial Gallons (Water)',
    code: 'waterGallonsImperial',
  },
  {
    name: 'US Gallons (Water)',
    code: 'waterGallonsUS',
  },
  {
    name: 'Litres (Water)',
    code: 'waterLitres',
  },
  {
    name: 'ppm (Air Quality)',
    code: 'ppm',
  },
  {
    name: 'Celsius (Temperature)',
    code: 'celsius',
  },
  {
    name: 'Fahrenheit (Temperature)',
    code: 'fahrenheit',
  },
  {
    name: 'Kelvin (Temperature)',
    code: 'kelvin',
  },
  {
    name: 'Boolean',
    code: 'boolean',
  },
  {
    name: 'Unknown',
    code: 'unknown',
  },
] as const
