import { Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import Root from './layouts/root'
import Contact from './Pages/contact'
import ErrorPage from './Pages/error'
import Home from './Pages/home'
import Support from './Pages/support'
import Terms from './Pages/terms'
import Privacy from './Pages/privacy'
import Buildings from './Pages/buildings/buildings'
import WrappedBuilding from './Pages/buildings/building'
import { RequireAuth } from './hooks/use-auth'
import SignIn from './Pages/signIn'
import SignOut from './Pages/signOut'
import Loader from './elements/loader'
import DashboardWrapper from './Pages/dashboards/dashboardWrapper'
import { WrappedBuildingData } from './Pages/buildings/buildingData'
import Channels from './Pages/channels/channels'
import Users from './Pages/users/users'
import Companies from './Pages/companies/companies'
import { SystemAdministration } from './Pages/systemAdministration/systemAdministration'
import SetResetPassword from './Pages/setResetPassword'
import { BuildingIssues } from './Pages/buildings/issues'

const router = createBrowserRouter([
  {
    path: '/dashboard/:buildingSlug/',
    element: <DashboardWrapper />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: 'signin', element: <SignIn /> },
      { path: 'signout', element: <SignOut /> },
      {
        path: 'verify',
        element: <SetResetPassword />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'reset',
        element: <SetResetPassword />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'changePassword',
        element: (
          <RequireAuth>
            <Suspense fallback={<Loader />}>
              <SetResetPassword />
            </Suspense>
          </RequireAuth>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'terms',
        element: <Terms />,
      },
      {
        path: 'privacy',
        element: (
          <RequireAuth>
            <Privacy />
          </RequireAuth>
        ),
      },
      {
        path: 'users',
        element: (
          <RequireAuth>
            <Users />
          </RequireAuth>
        ),
      },
      {
        path: 'companies',
        element: (
          <RequireAuth>
            <Companies />
          </RequireAuth>
        ),
      },
      {
        path: 'system-administration',
        element: (
          <RequireAuth>
            <Suspense fallback={<Loader />}>
              <SystemAdministration />
            </Suspense>
          </RequireAuth>
        ),
      },
      {
        path: 'buildings',
        children: [
          {
            index: true,
            element: (
              <RequireAuth>
                <Suspense fallback={<Loader />}>
                  <Buildings />
                </Suspense>
              </RequireAuth>
            ),
          },
          // {
          //   path: 'new',
          //   element: (
          //     <RequireAuth>
          //       <Suspense fallback={<Loader />}>
          //         <NewBuilding />
          //       </Suspense>
          //     </RequireAuth>
          //   ),
          // },
          {
            path: ':buildingSlug',
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<Loader />}>
                    <WrappedBuilding />
                  </Suspense>
                ),
              },
              { path: 'update', element: <p>Update building form here</p> },
              {
                path: 'delete',
                element: <p>Replace with delete and redirect</p>,
              },
              { path: 'data', element: <WrappedBuildingData /> },
              {
                path: 'dashboard',
                element: (
                  <Suspense fallback={<Loader />}>
                    <DashboardWrapper />
                  </Suspense>
                ),
              },
              {
                path: 'issues',
                element: <BuildingIssues />,
              },
              {
                path: 'channels',
                children: [
                  {
                    index: true,
                    element: <Channels />,
                  },
                  { path: 'new', element: <p>New channel form</p> },
                  {
                    path: ':channelSlug',
                    children: [
                      { index: true, element: <p>channel data page</p> },
                      {
                        path: 'update',
                        element: <p>channel edit form here</p>,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
])

export default router
