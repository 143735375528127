import {
  Flex,
  Heading,
  Box,
  Input,
  FormControl,
  FormLabel,
  Button,
  useToast,
  Select,
  Spinner,
} from '@chakra-ui/react'
import { Check } from 'react-feather'
import { FieldValues, useForm } from 'react-hook-form'
import {
  CarbonData,
  CategorisedCarbonList,
} from './categorisedUnitList/categorisedUnitList'
import { GET_ELECTRICITY_CARBON } from '../../graphql/API/queries'
import {
  useElectricityCarbonCreateMutation,
  useElectricityCarbonDeleteMutation,
  useElectricityCarbonGetAllQuery,
} from '../../graphql/generated/graphql'
import { countryCodes } from '../../utils/locationMaps/countryCodes'
import { useState } from 'react'

export const ElectricityCarbon = () => {
  const toast = useToast()
  const toastSuccess = (message: string) =>
    toast({
      title: message,
      status: 'success',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })
  const toastFail = (message: string) =>
    toast({
      title: message,
      status: 'error',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })

  const { handleSubmit, register } = useForm()

  const [getDeletingIds, setDeletingIds] = useState<string[]>([])

  const [sendCarbonCreate, { loading: awaitingCreate }] =
    useElectricityCarbonCreateMutation()
  const [sendCarbonDelete] = useElectricityCarbonDeleteMutation()

  const { data, loading: awaitingData } = useElectricityCarbonGetAllQuery()
  const unsortedData = data?.electricityCarbonGetAll

  const submitCarbon = (values: FieldValues) => {
    sendCarbonCreate({
      variables: {
        data: {
          country: values.country,
          date: new Date(values.date),
          value: Number(values.value),
        },
      },
      refetchQueries: [
        {
          query: GET_ELECTRICITY_CARBON,
        },
      ],
      onCompleted: () =>
        toastSuccess('Successfully retrieved new electricity carbon data'),
      onError: (error) => toastFail(error.message),
    })
  }

  const submitCarbonDelete = (id: string) => {
    setDeletingIds([...getDeletingIds, id])
    sendCarbonDelete({
      variables: {
        data: {
          id,
        },
      },
      refetchQueries: [{ query: GET_ELECTRICITY_CARBON }],
      onCompleted: () => {
        setDeletingIds(getDeletingIds.filter((deletingId) => deletingId !== id))
        toastSuccess('Successfully deleted electricity carbon data')
      },
      onError: (error) => {
        setDeletingIds(getDeletingIds.filter((item) => item !== id))
        toastFail(error.message)
      },
    })
  }

  // Sort the data for the table
  const sortedData: CarbonData = {}
  if (unsortedData) {
    for (const datum of unsortedData) {
      if (!(datum.country in sortedData)) {
        sortedData[datum.country] = []
      }
      sortedData[datum.country].push({
        id: datum.id,
        date: new Date(datum.date),
        value: datum.value,
      })
    }
  }
  for (const country in sortedData) {
    sortedData[country].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    )
  }

  return (
    <>
      <form
        style={{ display: 'flex', gap: '8px', alignItems: 'end' }}
        onSubmit={handleSubmit(submitCarbon)}
      >
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Select {...register('country')}>
            {countryCodes.map((item) => (
              <option value={item.code}>{item.name}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel whiteSpace={'nowrap'}>Date</FormLabel>
          <Input type="date" {...register('date')} />
        </FormControl>
        <FormControl>
          <FormLabel>Carbon Value</FormLabel>
          <Input type={'number'} placeholder={'value'} {...register('value')} />
        </FormControl>
        {awaitingCreate ? (
          <Box>
            <Spinner size={'lg'} verticalAlign={'center'} />
          </Box>
        ) : (
          <Button leftIcon={<Check />} iconSpacing={0} type={'submit'} />
        )}
      </form>

      <Box paddingTop={4}>
        <Flex flexDirection="column" gap={4}>
          <Heading paddingTop={8}>View Data</Heading>
          {awaitingData ? (
            <Spinner size="xl" alignSelf={'center'} />
          ) : (
            <CategorisedCarbonList
              data={sortedData}
              getDeletingIds={getDeletingIds}
              submitDelete={submitCarbonDelete}
            />
          )}
        </Flex>
      </Box>
    </>
  )
}
