import Nestable from 'react-nestable'
import 'react-nestable/dist/styles/index.css'
import {
  Box,
  Button,
  Center,
  Heading,
  Icon,
  IconButton,
  Tooltip,
  useBreakpoint,
} from '@chakra-ui/react'
import { ArrowRight, ArrowDown, Move } from 'react-feather'
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { isCurrentUserEndUser } from '../../utils/helpers/basicFunc'
import { ReactNode, useState } from 'react'
import {
  CombiWithAddedProps,
  InitialData,
  RelWithAddedProps,
} from '../../Pages/channels/channels'
import { Channel } from '../../graphql/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import './hierarchyList.css'
import TargetAlerts from '../../Pages/targetAlerts/targetAlerts'
import {
  areArraysOfObjectsEqual,
  sortTree,
} from '../../utils/helpers/channelHelpers'

const cssCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
const handlerStyles = {
  width: '2rem',
  height: '100%',
  borderRight: '1px solid Gainsboro',
}

type RenderItemProps = {
  item: RelWithAddedProps | CombiWithAddedProps
  collapseIcon: ReactNode
  handler: ReactNode
  onEditCombiDrawer?: (
    arg1: CombiWithAddedProps & { children?: CombiWithAddedProps[] },
  ) => void
  from: string
  onVirtualOpen?: (arg1: { id: string; buildingId: string }) => void
  onAlertClick: (arg1: CombiWithAddedProps) => void
  onDeleteClick: (arg1: CombiWithAddedProps | RelWithAddedProps) => void
  breakpoint: string
}

const renderItem = (props: RenderItemProps) => {
  const {
    item,
    collapseIcon,
    handler,
    onEditCombiDrawer,
    from,
    onVirtualOpen,
    onAlertClick,
    onDeleteClick,
    breakpoint,
  } = props
  const isCombiWithAddedProps = (
    item: RelWithAddedProps | CombiWithAddedProps,
  ): item is CombiWithAddedProps => 'composite' in item

  const havePermissions = !isCurrentUserEndUser()

  const canAddVirtual =
    havePermissions &&
    breakpoint !== 'base' &&
    from === 'rel' &&
    !item.hasVirtual &&
    item?.children?.length &&
    !item?.children.some((datum) => {
      if (datum?.channelType === 'virtual') {
        return true
      }
      return false
    })

  return (
    <Box
      bg={'#f1f1f1'}
      display={'flex'}
      p={'10px'}
      position={'relative'}
      borderRadius={'0px'}
      alignItems={'center'}
      className={
        from === 'combi' && (item as CombiWithAddedProps).composite
          ? 'combi'
          : from === 'rel'
            ? `rel ${item.channelType ?? ''}`
            : item.channelType ?? ''
      }
    >
      {breakpoint !== 'base' &&
      havePermissions &&
      ((from === 'combi' && (item as CombiWithAddedProps).composite) ||
        (from === 'rel' && item.channelType === 'actual' && !item.singleChild))
        ? handler
        : ''}
      {collapseIcon}
      <div
        style={{
          paddingLeft: 10,
          flex: 1,
        }}
      >
        {item.name}
      </div>
      {canAddVirtual ? (
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="solid"
          onClick={() =>
            onVirtualOpen?.({
              id: item.id as string,
              buildingId: item.buildingId as string,
            })
          }
          size={'sm'}
        >
          Virtual
        </Button>
      ) : (
        ''
      )}
      {breakpoint !== 'base' &&
        havePermissions &&
        from === 'rel' &&
        item.channelType === 'virtual' && (
          <IconButton
            aria-label="delete-channel"
            icon={<DeleteIcon />}
            colorScheme={'red'}
            size={'sm'}
            mr={3}
            onClick={() => onDeleteClick(item)}
          />
        )}
      {breakpoint !== 'base' &&
        from === 'combi' &&
        isCombiWithAddedProps(item) &&
        item?.composite && (
          <>
            {!isCurrentUserEndUser() && (
              <Button
                onClick={() => onAlertClick(item)}
                variant={'outline'}
                colorScheme={'purple'}
                mr={3}
                size={'sm'}
                bg={'white'}
              >
                View Alerts
              </Button>
            )}

            {havePermissions && (
              <IconButton
                aria-label="edit-channel"
                size={'sm'}
                icon={<EditIcon />}
                colorScheme={'blue'}
                mr={3}
                onClick={() => onEditCombiDrawer?.(item)}
              />
            )}

            {!item.isDashboardDefault && havePermissions && (
              <IconButton
                aria-label="delete-channel"
                icon={<DeleteIcon />}
                colorScheme={'red'}
                size={'sm'}
                mr={3}
                onClick={() => onDeleteClick(item)}
              />
            )}
          </>
        )}
    </Box>
  )
}

export default function HierachyList({
  channels,
  from,
  onEditCombiDrawer,
  onVirtualOpen,
  channelReorder,
  initialData,
  objectChanged,
  isOnDelete,
}: {
  channels: RelWithAddedProps[] | CombiWithAddedProps[]
  from: string
  onVirtualOpen?: (arg1: { id: string; buildingId: string }) => void
  onEditCombiDrawer?: (
    arg1: CombiWithAddedProps & { children?: CombiWithAddedProps[] },
  ) => void
  channelReorder: (arg1: {
    from: string
    channels: RelWithAddedProps[] | CombiWithAddedProps[]
  }) => void
  initialData: InitialData
  objectChanged: (arg1: boolean, arg2: string) => void
  isOnDelete?: (arg: RelWithAddedProps | CombiWithAddedProps) => void
}) {
  const [isAlertsOpen, setAlertsModal] = useState(false)
  const [clickedElement, setClickedElement] = useState<CombiWithAddedProps>()
  const breakpoint = useBreakpoint()

  const Handler = () => {
    return (
      <Tooltip label={'Drag'}>
        <div style={{ ...cssCenter, ...handlerStyles, cursor: 'grab' }}>
          <Icon as={Move} />
        </div>
      </Tooltip>
    )
  }
  const Collapser = ({ isCollapsed }: { isCollapsed: boolean }) => {
    return (
      <Tooltip label={isCollapsed ? 'Expand' : 'Condense'}>
        <div style={{ ...cssCenter, ...handlerStyles, cursor: 'pointer' }}>
          {isCollapsed ? <Icon as={ArrowRight} /> : <Icon as={ArrowDown} />}
        </div>
      </Tooltip>
    )
  }

  const confirmChange = ({
    dragItem,
    destinationParent,
  }: {
    dragItem: RelWithAddedProps | CombiWithAddedProps
    destinationParent: RelWithAddedProps | CombiWithAddedProps | null
  }) => {
    if (from === 'rel') {
      // to avoid placing virtual inside another virtual channel
      return destinationParent?.channelType !== 'virtual'
    }
    if (from === 'combi') {
      return (
        // condition to avoid duplicate channels inside combi
        !destinationParent?.children?.some(
          (datum: Maybe<Channel>): datum is CombiWithAddedProps =>
            datum !== null && datum?.id === dragItem.id,
        ) &&
        (destinationParent === null ||
          !!(destinationParent as CombiWithAddedProps).composite)
      )
    }
    return true
  }

  const onDragChange = (dragParams: {
    dragItem: RelWithAddedProps | CombiWithAddedProps
    items: RelWithAddedProps[] | CombiWithAddedProps[]
    targetPath: number[]
  }) => {
    const { items } = dragParams,
      reArrangedItems =
        from === 'rel'
          ? items
          : resetCombiStructure(items as CombiWithAddedProps[])
    objectChanged(
      !areArraysOfObjectsEqual(
        // to check whether the reorder items are same as before to enable save button
        initialData[from]!,
        sortTree(
          reArrangedItems as (CombiWithAddedProps | RelWithAddedProps)[],
          from,
        ),
      ),
      from,
    )
    channelReorder({
      channels: reArrangedItems,
      from,
    })
  }

  const resetCombiStructure = (items: CombiWithAddedProps[]) => {
    return items.some((item) => !item.composite) ? [...channels] : items
  }

  const onAlertClick = (details: CombiWithAddedProps) => {
    setClickedElement(details)
    setAlertsModal(true)
  }

  const onCloseAlerts = () => {
    setAlertsModal(false)
  }
  const onDeleteClick = (details: CombiWithAddedProps | RelWithAddedProps) => {
    isOnDelete!(details)
  }

  return !channels.length ? (
    <Center height={'50vh'}>
      <Heading>No Data</Heading>
    </Center>
  ) : (
    <Box p={'1%'}>
      <Nestable
        className={`nestable-plugin ${breakpoint === 'base' ? 'base' : ''}`}
        items={channels}
        idProp={'id'}
        group={from}
        collapsed={false}
        renderItem={(props) =>
          renderItem({
            ...props,
            onEditCombiDrawer,
            from,
            onVirtualOpen,
            onAlertClick,
            onDeleteClick,
            breakpoint,
          })
        }
        maxDepth={5}
        handler={<Handler />}
        renderCollapseIcon={({ isCollapsed }) => (
          <Collapser isCollapsed={isCollapsed} />
        )}
        onChange={onDragChange}
        confirmChange={confirmChange}
      />
      {isAlertsOpen && (
        <TargetAlerts
          isOpen={isAlertsOpen}
          onClose={onCloseAlerts}
          channelId={clickedElement?.id!}
          channelName={clickedElement?.name ?? ''}
        />
      )}
    </Box>
  )
}
